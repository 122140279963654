import React from 'react'
import Select from 'react-select'
import {Controller, useController} from 'react-hook-form'

import theme from '../../constants/app.theme';

const sample_options = [
  { value: 'year', label: 'Year' },
  { value: 'month', label: 'Month' },
  // { value: 'custom', label: 'Custom' }
]

function DropDownSelect(props) {
  // const {options = sample_options, setFilterType, defaultValue, register} = props

  const { options, name,
    defaultValue, value,
    onChange, isDisabled, onFocus, setSelected, control, multiple = false } = props;

  const { field } = useController({ name, control });
  const { value: FieldValue, onChange: OnChangeFieldValue, ...restField } = field;


  console.log(defaultValue, 'defaultValue')
  

    const colourStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected, ...rest }) => {
          return {
            ...styles,
            backgroundColor: isFocused ? "rgba(15, 118, 110, 0.9)" : null,
            color: isFocused ? "#fff" : "#000",
            height: 35,
            fontSize: 14,
          };
        },
        control: base => ({
          ...base,
          height: 35,
          minHeight: 35
        })
      };

    return (
        <Select
            className="React"
            classNamePrefix="select"
            options={options}
            isDisabled={isDisabled}
            defaultValue={defaultValue}
            onFocus={onFocus}
            isClearable={false}
            value={FieldValue ? options.find(x => x.value === FieldValue) : FieldValue}
            onChange={option => {
              setSelected(option)
              OnChangeFieldValue(option ? option.value : option)
            }}
            isMulti={multiple}
            {...restField}
            classNames={{
                control: (state) =>
                state.isFocused ? 'border-theme_color-600' : 'border-gray-100',
            }}
            styles={colourStyles}
            theme={(theme) => ({
              ...theme,
              borderRadius: 1,
              height: 40,
              colors: {
              ...theme.colors,
                primary25: 'rgba(15, 118, 110, 0.3)',
                primary50: 'rgba(15, 118, 110, 0.5)',
                primary75: 'rgba(15, 118, 110, 0.7)',
                primary: 'rgba(15, 118, 110, 0.9)',
              },
            })}
        />
    )
}

export default DropDownSelect