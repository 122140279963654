import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {useForm} from 'react-hook-form'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import DropDownSelect from './dropdown_select'

function CustomFieldModalComponent({setProperty, modal, addCustomField, customFieldEdit, editCustomField}) {
  const {register, control, handleSubmit, setValue, formState: {errors}} = useForm()
    const [open, setOpen] = useState(modal)
    const [fieldTypeList, setFieldFieldTypeList] = useState([
      {label: "Text", value: "text"}, 
      {label: "Text Area", value: "textarea"}, 
      {label: "Date", value: "date"}, 
      {label: "Time", value: "time"}, 
      {label: "Dropdown", value: "select"},
      {label: "Checkbox", value: "checkbox"},
      {label: "Radio", value: "radio"},
    ])
    const [selectedFieldType, setSelectedFieldType] = useState("")

    const type = customFieldEdit ? "edit" : "add"

    useEffect(() => {
      setOpen(modal)
      if(customFieldEdit){
        setValue('field_type', fieldTypeList.find(x => x.value == customFieldEdit.type))
        setValue('field_name', customFieldEdit.name)
        setValue('placeholder', customFieldEdit.placeholder)
        setValue('description', customFieldEdit.description)
        setValue('options', customFieldEdit.options.join('\n'))
        setValue('hidden_field', customFieldEdit.hidden)
        setValue('visible_only_admin', customFieldEdit.visible_only_admin)
      }else{
        setValue('field_type', "")
        setValue('field_name', "")
        setValue('placeholder', "")
        setValue('description', "")
        setValue('options', "")
        setValue('hidden_field', false)
        setValue('visible_only_admin', false)
      }
    },[modal, customFieldEdit])

    const onSubmit = (values) => {
      console.log(values, 'values')
      if(customFieldEdit){
        return editCustomField(values, customFieldEdit)
      }
      addCustomField(values)
    }

  const cancelButtonRef = useRef(null)
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setProperty}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-sm bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg overflow-visible">
                <form>
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 w-full">
                    <div className="sm:flex sm:items-start w-full">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-0 sm:text-left w-full">
                        <Dialog.Title as="h3" className="text-base font-normal leading-6 text-gray-900 font-roboto">
                          CUSTOM FIELD CONFIGURE
                        </Dialog.Title>
                          <div className="mt-4 grid grid-cols-2 gap-2">
                            <div className='mb-2'>
                              <label htmlFor="field-type" className='text-sm font-ubuntu'>
                                Field Type
                              </label>
                              <DropDownSelect
                                register={register}
                                options={fieldTypeList}
                                defaultValue={customFieldEdit && fieldTypeList.find(x => x.value == customFieldEdit.type)}
                                setSelected={(e) => { setSelectedFieldType(e.value)}}
                                control={control}
                                name={"field_type"}
                              />
                              {errors.field_type && <span className='text-xs text-red-700'>This field is required</span>}
                            </div>
                            <div className='mb-2'>
                              <label htmlFor="field-name" className='text-sm font-ubuntu'>
                                Field Name
                              </label>
                              <input
                                id="field-name"
                                name="field_name"
                                type="text"
                                {...register("field_name", { required: true })}
                                autoComplete="field_name"
                                className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.field_name ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.field_name ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.field_name ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.field_name && "error-input"}`}
                                placeholder=""
                              />
                              {errors.field_name && <span className='text-xs text-red-700'>This field is required</span>}
                            </div>
                            <div className='mb-2'>
                              <label htmlFor="placeholder" className='text-sm font-ubuntu'>
                                Placeholder
                              </label>
                              <input
                                id="placeholder"
                                name="placeholder"
                                type="text"
                                {...register("placeholder", { required: false })}
                                autoComplete="placeholder"
                                className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.placeholder ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.placeholder ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.placeholder ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.placeholder && "error-input"}`}
                                placeholder=""
                              />
                              {errors.placeholder && <span className='text-xs text-red-700'>This field is required</span>}
                            </div>
                            <div className='mb-2'>
                              <label htmlFor="description" className='text-sm font-ubuntu'>
                                Description
                              </label>
                              <input
                                id="description"
                                name="description"
                                type="text"
                                {...register("description", { required: false })}
                                autoComplete="description"
                                className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.description ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.description ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.description ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.description && "error-input"}`}
                                placeholder=""
                              />
                              {errors.description && <span className='text-xs text-red-700'>This field is required</span>}
                            </div>
                            { (selectedFieldType == "select" || selectedFieldType == "checkbox" || selectedFieldType == "radio") && <div className='mb-2'>
                              <label htmlFor="options" className='text-sm font-ubuntu'>
                                Options
                              </label>
                              <textarea
                                id="options"
                                name="options"
                                type="textarea"
                                {...register("options", { required: false })}
                                autoComplete="options"
                                className={`relative block w-full appearance-none rounded-xs border-light-grey ${errors.options ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.options ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.options ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.options && "error-input"}`}
                                placeholder=""
                              />
                              {errors.options && <span className='text-xs text-red-700'>This field is required</span>}
                            </div>}
                          </div>
                          <div>
                            <div className='mt-4 mb-4 font-normal font-roboto'>VISIBILITY</div>
                            <div className='py-2'>
                              <div className="flex items-center">
                                <input
                                  id="hidden-field"
                                  name="hidden_field"
                                  type="checkbox"
                                  {...register("hidden_field", { required: false })}
                                  className={`h-4 w-4 rounded border-gray-300 text-theme_color-600 ring-theme_color-500 focus:ring-theme_color-500 focus:ring-offset-2`}
                                />
                                <label htmlFor="hidden-field" className="ml-2 block text-sm text-gray-900 font-ubuntu">
                                  Hidden Field
                                </label>
                              </div>
                              <div className="flex items-center mt-3">
                                <input
                                  id="visible-only-admin"
                                  name="visible_only_admin"
                                  type="checkbox"
                                  {...register("visible_only_admin", { required: false })}
                                  className={`h-4 w-4 rounded border-gray-300 text-theme_color-600 ring-theme_color-500 focus:ring-theme_color-500 focus:ring-offset-2`}
                                />
                                <label htmlFor="visible-only-admin" className="ml-2 block text-sm text-gray-900 font-ubuntu">
                                  Visible Only Admin
                                </label>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="font-roboto mt-3 inline-flex w-full justify-center rounded-sm bg-white px-3 py-1 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setProperty()}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="font-roboto inline-flex w-full justify-center rounded-sm bg-theme_color-600 px-3 py-1 text-sm text-white shadow-sm hover:bg-theme_color-700 sm:ml-3 sm:w-auto mr-3"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Confirm
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default CustomFieldModalComponent