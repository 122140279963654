import { IconButton } from '@mui/material'
import React, { useLayoutEffect, useState, Fragment, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import CustomFieldComponent from '../../component/ui_component/customfield'
import DropDownSelect from '../../component/ui_component/dropdown_select'

function VendorAdd(props) {
  const navigate = useNavigate()
  const { register, control, handleSubmit, formState: { errors } } = useForm()
  const [categoryList, setCategoryList] = useState([{ label: "General", value: "general" }, { label: "VIB", value: 'vib' }])
  const [customFieldList, setCustomFieldList] = useState([])
  const [selectedVendor, setSelectedVendor] = useState(null)
  const [actionType, setActionType] = useState('add')

  const url = window.location.href

  useLayoutEffect(() => {
    if(props.actionType){
      setActionType(props.actionType)
    }
  }, [props.actionType])


  const onSubmit = (values) => {
    console.log(values, 'values 1')
    console.log(customFieldList, 'custom_field list')

    const payload = {
      vendor_address: {
        street: values.vendor_address_street,
        city: values.vendor_address_city,
        state: values.vendor_address_state,
        country: values.vendor_address_country,
        zip_code: values.vendor_address_zipcode
      },
      vendor_first_name: values.vendor_first_name,
      vendor_last_name: values.vendor_last_name,
      vendor_email: values.vendor_email,
      vendor_phone_number: values.vendor_phone_number,
      vendor_category: values.vendor_category
    }

    if(actionType == "add"){
      addVendor(values)
    }
    else if(actionType == "edit"){
      props.editVendor(values)
    }
  }

  function addVendor(values){
    console.log(values)
    console.log('add vendor')
  }

  const addCustomField = (values) => {
    const payload = {
      name: values.field_name,
      type: values.field_type,
      placeholder: values.placeholder,
      description: values.description,
      options: values.options ? values.options.split('\n') : [],
      hidden: values.hidden_field,
      visible_only_admin: values.visible_only_admin,
      index: customFieldList.length + 1
    }
    setCustomFieldList([...customFieldList, payload])
  }

  const editCustomField = (values, prev_values) => {
    console.log({ values, prev_values })
    const findIndex = customFieldList.findIndex(x => x.index == prev_values.index)
    const clone = customFieldList
    const payload = {
      name: values.field_name,
      type: values.field_type.value ? values.field_type.value : values.field_type,
      placeholder: values.placeholder,
      description: values.description,
      options: values.options ? values.options.split('\n') : [],
      hidden: values.hidden_field,
      visible_only_admin: values.visible_only_admin,
      index: prev_values.index
    }
    if (findIndex > -1) {
      clone[findIndex] = payload
    } else {
      clone.push({ ...payload, index: customFieldList.length + 1 })
    }
    console.log(clone, 'clone')
    setCustomFieldList([...clone])
  }

  const deleteCustomField = (field) => {
    const clone = [...customFieldList]
    clone.splice(field.index, 1)
    setCustomFieldList(clone)
    deleteCustomField(field)
  }

  function disableElement(){
    if(actionType == "view"){
      return true
    }
    return false
  }

  function actionHandler(){
    if(props.actionType == "view"){
      if(actionType == "view") setActionType('edit')
      else setActionType('view')
    }
  }

  return (
    <div className='flex flex-col h-full'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='sticky'>
          <div className='flex items-center justify-between'>
            <div className='h-14 font-semibold font-roboto'><span className='cursor-pointer' onClick={() => navigate("/vendor")}>Vendor</span> {actionType == "add" ? <span>/ New</span> : <span className='cursor-pointer' onClick={() => navigate("/vendor/97849/details")}>/ Kathiravan</span>} {actionType == "edit" && "/ Edit"} </div>
            {actionType != 'edit' && <button
              type="submit"
              className="font-roboto inline-flex w-full justidfy-center rounded-sm bg-theme_color-600 px-3 py-1 text-sm text-white shadow-sm hover:bg-theme_color-700 sm:ml-3 sm:w-auto mr-3"
            onClick={() => actionHandler()}
            >
              {actionType == "add" ? "Create" : actionType == "edit" ? "Update" : "Edit"}
            </button>}
          </div>
        </div>
        <div className='flex flex-col h-full'>
          <div className='p-2 flex items-start justify-between'>
            <div className='box-card mr-2 p-4' style={{ width: '100%' }}>
              <div className='grid grid-cols-4 gap-3'>
                <div className='mb-2'>
                  <label htmlFor="vendor-name" className='text-sm font-ubuntu'>
                    Vendor Name
                  </label>
                  <input
                    id="vendor-name"
                    name="vendor_name"
                    type="text"
                    disabled={disableElement()}
                    {...register("vendor_name", { required: true })}
                    autoComplete="vendor_name"
                    className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.vendor_name ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.vendor_name ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.vendor_name ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.vendor_name && "error-input"}`}
                    placeholder=""
                  />
                  {errors.vendor_name && <span className='text-xs text-red-700'>This field is required</span>}
                </div>
                <div className='mb-2'>
                  <label htmlFor="vendor-email" className='text-sm'>
                    Vendor Email
                  </label>
                  <input
                    id="vendor-email"
                    name="vendor_email"
                    type="text"
                    disabled={disableElement()}
                    {...register("vendor_email", { required: true })}
                    autoComplete="vendor_email"
                    className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.vendor_email ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.vendor_email ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.vendor_email ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.vendor_email && "error-input"}`}
                    placeholder=""
                  />
                  {errors.vendor_email && <span className='text-xs text-red-700'>This field is required</span>}
                </div>
                <div className='mb-2'>
                  <label htmlFor="vendor-phone-number" className='text-sm font-ubuntu'>
                    Vendor Phone Number
                  </label>
                  <input
                    id="vendor-phone-number"
                    name="vendor_phone_number"
                    type="text"
                    disabled={disableElement()}
                    {...register("vendor_phone_number", { required: true })}
                    autoComplete="vendor_phone_number"
                    className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.vendor_phone_number ? "border-red-700" : `border-gray-500`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.vendor_phone_number ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.vendor_phone_number ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.vendor_phone_number && "error-input"}`}
                    placeholder=""
                  />
                  {errors.vendor_phone_number && <span className='text-xs text-red-700'>This field is required</span>}
                </div>
                <div className='mb-2'>
                  <label htmlFor="vendor-category" className='text-sm font-ubuntu'>
                    Vendor Category
                  </label>
                  <DropDownSelect
                    register={register}
                    options={categoryList}
                    defaultValue={categoryList[0]}
                    setSelected={(e) => { console.log(e, 'e') }}
                    control={control}
                    name={"vendor_category"}
                  />
                  {errors.vendor_category && <span className='text-xs text-red-700'>This field is required</span>}
                </div>
              </div>
              <div className='mt-4'>
                <div>
                  <div className='font-normal font-roboto'>Vendor Address</div>
                  <div className='grid grid-cols-4 gap-3 mt-2'>
                    <div className='mb-2'>
                      <label htmlFor="service-address-street" className='text-sm font-ubuntu'>
                        Street
                      </label>
                      <input
                        id="service-address-street"
                        name="vendor_address_street"
                        type="text"
                        disabled={disableElement()}
                        {...register("vendor_address_street", { required: true })}
                        autoComplete="vendor_address_street"
                        className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.vendor_address_street ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.vendor_address_street ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.vendor_address_street ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.vendor_address_street && "error-input"}`}
                        placeholder=""
                      />
                      {errors.vendor_address_street && <span className='text-xs text-red-700'>This field is required</span>}
                    </div>
                    <div className='mb-2'>
                      <label htmlFor="service-address-city" className='text-sm font-ubuntu'>
                        City
                      </label>
                      <input
                        id="service-address-city"
                        name="vendor_address_city"
                        type="text"
                        disabled={disableElement()}
                        {...register("vendor_address_city", { required: true })}
                        autoComplete="vendor_address_city"
                        className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.vendor_address_city ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.vendor_address_city ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.vendor_address_city ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.vendor_address_city && "error-input"}`}
                        placeholder=""
                      />
                      {errors.vendor_address_city && <span className='text-xs text-red-700'>This field is required</span>}
                    </div>
                    <div className='mb-2'>
                      <label htmlFor="service-address-state" className='text-sm font-ubuntu'>
                        State
                      </label>
                      <input
                        id="service-address-state"
                        name="vendor_address_state"
                        type="text"
                        disabled={disableElement()}
                        {...register("vendor_address_state", { required: true })}
                        autoComplete="vendor_address_state"
                        className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.vendor_address_state ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.vendor_address_state ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.vendor_address_state ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.vendor_address_state && "error-input"}`}
                        placeholder=""
                      />
                      {errors.vendor_address_state && <span className='text-xs text-red-700'>This field is required</span>}
                    </div>
                    <div className='mb-2'>
                      <label htmlFor="service-address-country" className='text-sm font-ubuntu'>
                        Country
                      </label>
                      <input
                        id="service-address-country"
                        name="vendor_address_country"
                        type="text"
                        disabled={disableElement()}
                        {...register("vendor_address_country", { required: true })}
                        autoComplete="vendor_address_country"
                        className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.vendor_address_country ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.vendor_address_country ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.vendor_address_country ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.vendor_address_country && "error-input"}`}
                        placeholder=""
                      />
                      {errors.vendor_address_country && <span className='text-xs text-red-700'>This field is required</span>}
                    </div>
                    <div className='mb-2'>
                      <label htmlFor="service-address-zipcode" className='text-sm font-ubuntu'>
                        ZIP Code
                      </label>
                      <input
                        id="service-address-zipcode"
                        name="vendor_address_zipcode"
                        type="text"
                        disabled={disableElement()}
                        {...register("vendor_address_zipcode", { required: true })}
                        autoComplete="vendor_address_zipcode"
                        className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.vendor_address_zipcode ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.vendor_address_zipcode ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.vendor_address_zipcode ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.vendor_address_zipcode && "error-input"}`}
                        placeholder=""
                      />
                      {errors.vendor_address_zipcode && <span className='text-xs text-red-700'>This field is required</span>}
                    </div>
                  </div>
                </div>
              </div>
              <div className='mt-3'>
                <CustomFieldComponent
                  register={register}
                  control={control}
                  customFieldList={customFieldList}
                  setCustomFieldList={setCustomFieldList}
                  addCustomField={addCustomField}
                  editCustomField={editCustomField}
                  deleteCustomField={deleteCustomField}
                  identifireComponentName={"vendor"}
                  index={0}
                  grid_cols={4}
                  actionType={actionType}
                  component={"vendor"}
                />
              </div>

              {actionType == "edit" && <div className="bg-gray-50 py-3 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={() => actionHandler()}
                  className="mt-3 inline-flex w-full justify-center rounded-sm bg-white px-3 py-1 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-sm bg-theme_color-600 px-3 py-1 text-sm text-white shadow-sm hover:bg-theme_color-700 sm:ml-3 sm:w-auto mr-3"
                  onClick={handleSubmit(onSubmit)}
                >
                  Confirm
                </button>
              </div>}
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default VendorAdd