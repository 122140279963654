import React, { useState } from "react";
// import Datepicker from "react-tailwindcss-datepicker";
import DatePicker from "react-datepicker";
import dayjs from 'dayjs'

import {Controller, useController} from 'react-hook-form'


const DateComponent = (props) => {
  // const [startDate, setStartDate] = useState(new Date());
  const {setSelectedDate, selectedDate, showYearPicker = false, dateFormat = "yyyy-mm-dd", name = "", control} = props

  const { field } = useController({ name, control });
  const { value: FieldValue, onChange: OnChangeFieldValue, ...restField } = field;

  function get_date_format(){
    return dateFormat == "yyyy" ? "YYYY" : "YYYY-MM-DD"
  }

  function get_date(date = ""){
    console.log(date, 'date')
    if(new Date(date)){
      return new Date(date)
    }
    return new Date(`01-01-${date}`)
  }
  return (
    <DatePicker 
     selected={get_date(selectedDate)} 
     onChange={date => {
      const date_ = dayjs(new Date(date)).format(get_date_format())
        setSelectedDate(date_)
        OnChangeFieldValue(date_)
     }}
     className="w-full date-picker" 
     showYearPicker={showYearPicker}
     dateFormat={dateFormat}
     {...restField}
    />
  );
};

export default DateComponent