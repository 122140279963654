import React, { useState } from 'react'
import { IconButton } from '@mui/material'

import DropDownSelect from '../../component/ui_component/dropdown_select'

import { ReactComponent as EditIcon } from '../../resouces/svg/edit.svg'
import { ReactComponent as PlusIcon } from '../../resouces/svg/plus.svg'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import CustomFieldModalComponent from './custom_field.modal'

function CustomFieldComponent(props) {
    const { register, control, list, editCustomField, addCustomField, deleteCustomField, customFieldList, setCustomFieldList, identifireComponentName, index = 0, actionType, component, grid_cols = 2 } = props
    const [customFieldEdit, setCustomFieldEdit] = useState(null)
    const [customFieldModal, setCustomFieldModal] = useState(false)
    // const [customFieldList, setCustomFieldList] = useState(list || [])

    const toggleCustomFieldModal = () => {
        setCustomFieldModal(!customFieldModal)
    }

    const SelectCustomField = (payload) => {
        setCustomFieldEdit(payload)
        toggleCustomFieldModal()
    }

    const addCustomFieldFunc = (values) => {
        const payload = {
            name: values.field_name,
            type: values.field_type,
            placeholder: values.placeholder,
            description: values.description,
            options: values.options ? values.options.split('\n') : [],
            hidden: values.hidden_field,
            visible_only_admin: values.visible_only_admin,
            index: customFieldList.length + 1
        }
        setCustomFieldList([...customFieldList, payload])
        // addCustomField(values)
        toggleCustomFieldModal()
    }

    const editCustomFieldFunc = (values, prev_values) => {
        console.log({ values, prev_values })
        const findIndex = customFieldList.findIndex(x => x.index == prev_values.index)
        const clone = customFieldList
        const payload = {
            name: values.field_name,
            type: values.field_type.value ? values.field_type.value : values.field_type,
            placeholder: values.placeholder,
            description: values.description,
            options: values.options ? values.options.split('\n') : [],
            hidden: values.hidden_field,
            visible_only_admin: values.visible_only_admin,
            index: prev_values.index
        }
        if (findIndex > -1) {
            clone[findIndex] = payload
        } else {
            clone.push({ ...payload, index: customFieldList.length + 1 })
        }
        console.log(clone, 'clone')
        setCustomFieldList([...clone])
        // editCustomField(values, prev_values)
        toggleCustomFieldModal()
    }

    const DeleteCustomFieldFunc = (field) => {
        const clone = [...customFieldList]
        clone.splice(field.index - 1, 1)
        console.log(clone, 'clone')
        setCustomFieldList(clone)
        // deleteCustomField(field)
    }

    const generateCustomField = (field) => {
        if (field.type == "text" || field.type == "date" || field.type == "time") {
            return (
                <div className='mb-2'>
                    <label htmlFor={`${field.name.toLowerCase().replace(" ", "-")}`} className='text-sm flex items-center justify-between w-full mb-3'>
                        <span className='font-ubuntu'>{field.name}</span>
                        <span>
                            <IconButton onClick={() => SelectCustomField(field)}>
                                <EditIcon color="grey" size={14} style={{ color: 'rgba(0,0,0,0.5)', height: 20, width: 20 }} />
                            </IconButton>
                            <IconButton onClick={() => DeleteCustomFieldFunc(field)}>
                                <DeleteOutlineIcon style={{ color: 'rgba(255,0,0,0.5)', height: 20, width: 20 }}/>
                            </IconButton>
                        </span>
                    </label>
                    <input
                        id={`${field.name.toLowerCase().replace(" ", "-")}`}
                        name={`${index}_${identifireComponentName}_custom_field-${field.name.toLowerCase().replace(" ", "-")}`}
                        type={field.type}
                        {...register(`${index}_${identifireComponentName}_custom_field-${field.name.toLowerCase().replace(" ", "-")}`, { required: false })}
                        autoComplete={`${identifireComponentName}_custom_field-${field.name.toLowerCase().replace(" ", "-")}`}
                        className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey border-gray-700 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-theme_color-500 focus:outline-none focus:ring-theme_color-500 sm:text-sm mb-1`}
                        placeholder={field.placeholder || ""}
                    />
                    <span className='text-sm text-gray-300'>{field.description}</span>
                </div>
            )
        }
        else if (field.type == "textarea") {
            return (
                <div className='mb-2'>
                    <label htmlFor={`${field.name.toLowerCase().replace(" ", "-")}`} className='text-sm flex items-center justify-between w-full mb-3'>
                        <span className='font-ubuntu'>{field.name}</span>
                        <span>
                            <IconButton onClick={() => SelectCustomField(field)}>
                                <EditIcon color="grey" size={14} style={{ color: 'rgba(0,0,0,0.5)', height: 20, width: 20 }} />
                            </IconButton>
                            <IconButton onClick={() => DeleteCustomFieldFunc(field)}>
                                <DeleteOutlineIcon style={{ color: 'rgba(255,0,0,0.5)', height: 20, width: 20 }}/>
                            </IconButton>
                        </span>
                    </label>
                    <textarea
                        id={`${field.name.toLowerCase().replace(" ", "-")}`}
                        name={`${index}_${identifireComponentName}_custom_field-${field.name.toLowerCase().replace(" ", "-")}`}
                        type="text"
                        {...register(`${index}_${identifireComponentName}_custom_field-${field.name.toLowerCase().replace(" ", "-")}`, { required: false })}
                        autoComplete={`${index}_${identifireComponentName}_custom_field-${field.name.toLowerCase().replace(" ", "-")}`}
                        className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey border-gray-700 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-theme_color-500 focus:outline-none focus:ring-theme_color-500 sm:text-sm mb-1`}
                        placeholder={field.placeholder || ""}
                    />
                    <span className='text-sm text-gray-300'>{field.description}</span>
                </div>
            )
        }
        else if (field.type == "checkbox" || field.type == "radio") {
            return (
                <div className='mb-2'>
                    <label htmlFor={`${field.name.toLowerCase().replace(" ", "-")}`} className='text-sm flex items-center justify-between w-full mb-3'>
                        <span className='font-ubuntu'>{field.name}</span>
                        <span>
                            <IconButton onClick={() => SelectCustomField(field)}>
                                <EditIcon color="grey" size={14} style={{ color: 'rgba(0,0,0,0.5)', height: 20, width: 20 }} />
                            </IconButton>
                            <IconButton onClick={() => DeleteCustomFieldFunc(field)}>
                                <DeleteOutlineIcon style={{ color: 'rgba(255,0,0,0.5)', height: 20, width: 20 }}/>
                            </IconButton>
                        </span>
                    </label>
                    {field.options.map(opt => {
                        return (
                            <div className='flex items-center'>
                                <input
                                    id={`${field.name.toLowerCase().replace(" ", "-")}-${opt}`}
                                    name={`${index}_${identifireComponentName}_custom_field-${field.name.toLowerCase().replace(" ", "-")}-${opt}`}
                                    type={field.type}
                                    {...register(`${index}_${identifireComponentName}_custom_field-${field.name.toLowerCase().replace(" ", "-")}-${opt}`, { required: false })}
                                    className={`${field.type == "checkbox" ? "h-4 w-4 rounded" : "rounded-t-full rounded-b-full"}  border-gray-300 text-theme_color-600 ring-theme_color-500 focus:ring-theme_color-500 focus:ring-offset-2`}
                                />
                                <span className='ml-2'>{opt}</span>
                            </div>
                        )
                    })}
                    <span className='text-sm font-ubuntu text-gray-300'>{field.description}</span>
                </div>
            )
        }
        else if (field.type == "select") {
            return (
                <div className='mb-2'>
                    <label htmlFor={`${field.name.toLowerCase().replace(" ", "-")}`} className='text-sm flex items-center justify-between w-full mb-3'>
                        <span className='font-ubuntu'>{field.name}</span>
                        <span>
                            <IconButton onClick={() => SelectCustomField(field)}>
                                <EditIcon color="grey" size={14} style={{ color: 'rgba(0,0,0,0.5)', height: 20, width: 20 }} />
                            </IconButton>
                            <IconButton onClick={() => DeleteCustomFieldFunc(field)}>
                                <DeleteOutlineIcon style={{ color: 'rgba(255,0,0,0.5)', height: 20, width: 20 }}/>
                            </IconButton>
                        </span>
                    </label>
                    <DropDownSelect
                        register={register}
                        options={field.options.map(x => {
                            return { label: x, value: x }
                        })}
                        setSelected={(e) => { console.log(e, 'e') }}
                        control={control}
                        name={`${index}_${identifireComponentName}_custom_field-${field.name.toLowerCase().replace(" ", "-")}`}
                    />
                    <span className='text-sm text-gray-300'>{field.description}</span>
                </div>
            )
        }
    }

    function showAddButton(){
        if(component && component != "customer_vehicle" && component != "add_vehicle"){
            if(actionType == "edit" || actionType == "add") return true
            else return false
        }
        return true
    }

    return (
        <React.Fragment>
            <div className='flex items-center justify-between mb-4'>
                <span className='font-normal font-roboto'>Custom Fields</span>
                {showAddButton() && <span className='cursor-pointer'>
                    <IconButton style={{backgroundColor: 'rgba(0,0,0,0.1)'}} onClick={() => {
                        setCustomFieldEdit(null)
                        setCustomFieldModal(true)
                    }}>
                    <PlusIcon style={{height: 20, width: 20}}/>
                    </IconButton>
                </span>}
            </div>
            <div className={`grid grid-cols-${grid_cols} gap-2`}>
                {customFieldList.map(field => {
                    return generateCustomField(field)
                })}
            </div>
            <CustomFieldModalComponent setProperty={toggleCustomFieldModal} modal={customFieldModal} addCustomField={addCustomFieldFunc} customFieldEdit={customFieldEdit} editCustomField={editCustomFieldFunc} />
        </React.Fragment>
    )
}

export default CustomFieldComponent