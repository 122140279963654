import React from 'react'
import PurchaseOrderAction from './purchase_order.action'

function PurchaseOrderEdit() {

  return (
    <PurchaseOrderAction
      actionType={"edit"}
    />
  )
}

export default PurchaseOrderEdit