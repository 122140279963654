import React, { useEffect } from 'react'

import { IconButton } from '@mui/material'

import { ReactComponent as EditIcon } from '../../resouces/svg/edit.svg'
import { ReactComponent as DeleteIcon } from '../../resouces/svg/delete.svg'
import { ReactComponent as PlusIcon } from '../../resouces/svg/plus.svg'
import { ReactComponent as InfoIcon } from '../../resouces/svg/info.svg'

function VehicleCard({vehicle, deleteVehicle, index, ToggleVehicleModal, selectedVehicle, setSelectedVehicle}) {

    console.log(vehicle, 'vehicle')

    const EditVehicle = () => {
        setSelectedVehicle(vehicle)
        ToggleVehicleModal()
    }

  return (
    <div className='flex justify-between relative bg-white box-card mt-4 items-stretch h-24 vehicle-card transition' id='vehicle-card'>
        <div className='flex items-center p-2 w-full'>
            <div className='rounded-md shadow-sm mr-3 w-1/3 h-full flex items-center justify-center'>
            {vehicle.attachments && vehicle.attachments.length ? (
                <>
                    <img className='rounded-sm' style={{objectFit: 'cover', height: '100%', width: '100%'}} src={URL.createObjectURL(vehicle.attachments[0])}/>
                </>
            ) : (
                <>
                    <InfoIcon/>
                </>
            )}
            </div>
            <div className='flex flex-col justify-between w-2/3 h-full'>
            <div className='font-semibold'>{vehicle.vehicle_name}</div>
            <div className='text-sm text-secondary-500'>{vehicle.registration_number}</div>
            <div className='text-sm text-secondary-500'>{vehicle.vehicle_model}</div>
            </div>
        </div>
        <div className='flex flex-col items-center justify-between rounded-r-md h-full p-1' id={`action-button-component-${index}`}>
            <IconButton onClick={() => EditVehicle()}> 
            <EditIcon style={{height: 20, width: 20, color: "rgba(255,255,255,1)"}}/>
            </IconButton>
            <IconButton onClick={() => deleteVehicle(index)}> 
            <DeleteIcon style={{height: 20, width: 20, color: "rgba(255,255,255,1)"}}/>
            </IconButton>
        </div>
        </div>
  )
}

export default VehicleCard