import React, { useState } from 'react'
import { ReactComponent as FilterSVG } from '../../resouces/svg/filter.svg'
import {useNavigate} from 'react-router-dom'

import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ComfirmModalComponent from '../../component/ui_component/confirm.modal';
import TableComponent from '../../component/ui_component/table';

function VendorListPage(props) {
    const {setActionType} = props

    const navigation = useNavigate()
    const [deleteVendor, setDeleteVendor] = useState({modal: false, selected_details: {}})
  
    const columns = [
      {
        name: 'S.NO',
        selector: (row, index) => index + 1,
      },
      {
        name: 'VENDOR UNIQUE ID',
        selector: row => row.vendor_unique_id,
      },
      {
        name: 'VENDOR NAME',
        selector: row => row.vendor_name,
      },
      {
        name: 'VENDOR PHONE',
        selector: row => row.vendor_phone,
      },
      {
        name: 'VENDOR EMAIL',
        selector: row => row.vendor_email,
      },
      {
        name: 'ACTION',
        selector: row => (
          <>
            <IconButton onClick={() => {
              NavigationHandler(`/vendor/${'937392'}/details`)
            }}>
              <VisibilityIcon style={{ color: 'rgba(0,0,0,0.3)', size: '7px' }} />
            </IconButton>
            <IconButton onClick={() => {
              toggle_delete_vendor()  
            }}>
              <DeleteOutlineIcon style={{ color: 'rgba(255,0,0,0.5)', size: '5px' }} />
            </IconButton>
          </>
        )
      }
    ];
  
    const toggle_delete_vendor = () => {
      setDeleteVendor({modal: !deleteVendor.modal, ...setDeleteVendor.selected_details})
    }
  
    const delete_vendor = (payload) => {
      console.log(payload, 'payload')
    }
  
    const NavigationHandler = (url) => {
      navigation(url)
    }
  
    const docs = [
      {
        vendor_unique_id: '001',
        vendor_name: "kathiravan",
        vendor_phone: "76658855894",
        vendor_email: "temp@gmail.com"
      },
      {
        vendor_unique_id: '001',
        vendor_name: "kathiravan",
        vendor_phone: "76658855894",
        vendor_email: "temp@gmail.com"
      },
      {
        vendor_unique_id: '001',
        vendor_name: "kathiravan",
        vendor_phone: "76658855894",
        vendor_email: "temp@gmail.com"
      },
      {
        vendor_unique_id: '001',
        vendor_name: "kathiravan",
        vendor_phone: "76658855894",
        vendor_email: "temp@gmail.com"
      },
      {
        vendor_unique_id: '001',
        vendor_name: "kathiravan",
        vendor_phone: "76658855894",
        vendor_email: "temp@gmail.com"
      },
      {
        vendor_unique_id: '001',
        vendor_name: "kathiravan",
        vendor_phone: "76658855894",
        vendor_email: "temp@gmail.com"
      },
      {
        vendor_unique_id: '001',
        vendor_name: "kathiravan",
        vendor_phone: "76658855894",
        vendor_email: "temp@gmail.com"
      },
      {
        vendor_unique_id: '001',
        vendor_name: "kathiravan",
        vendor_phone: "76658855894",
        vendor_email: "temp@gmail.com"
      },
      {
        vendor_unique_id: '001',
        vendor_name: "kathiravan",
        vendor_phone: "76658855894",
        vendor_email: "temp@gmail.com"
      },
      {
        vendor_unique_id: '001',
        vendor_name: "kathiravan",
        vendor_phone: "76658855894",
        vendor_email: "temp@gmail.com"
      }
    ]
    return (
      <div className='flex flex-col h-full p-5 pt-0'>
        <div className='flex items-center justify-between h-10 mb-2'>
          <div className='font-roboto font-bold'>Vendor List</div>
          <div>
            <button class="font-roboto bg-theme_color-600 hover:bg-theme_color-700 text-white text-sm py-1 px-4 border border-theme_color-700 rounded mr-2" type="button" onClick={() => NavigationHandler(`/vendor/add`)}>
              Add New
            </button>
            <IconButton type="button" deleteVendor >
              <FilterSVG style={{ color: "rgba(0,0,0,0.3)" }} />
            </IconButton>
          </div>
        </div>
        <div className='flex-grow pb-4'>
          <TableComponent
            column={columns}
            docs={docs}
          />
          <ComfirmModalComponent setProperty={toggle_delete_vendor} modal={deleteVendor.modal}/>
        </div>
      </div>
    )
}

export default VendorListPage