import React from 'react'
import { useNavigate } from 'react-router-dom'
import sampleimage from '../../resouces/image/sample.png'

function AccordionComponent({navigateToVehicleDetailsScreen, navigateToVehicleDetailsEditScreen}) {
    return (
        <div id="accordionExample">
            <div
                class="rounded-t-lg border border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800">
                <h2 class="mb-0" id="headingOne">
                    <button
                        class="group relative flex w-full items-center rounded-t-[15px] border-0 bg-white p-3 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-teal [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-neutral-800 dark:[&:not([data-te-collapse-collapsed])]:text-teal dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)] font-ubuntu font-normal text-sm"
                        type="button"
                        data-te-collapse-init
                        data-te-collapse-collapsed
                        data-te-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne">
                        4272 - Hero Hoda
                        <span
                            class="ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#115e59] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#115e59] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="h-6 w-6">
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>
                        </span>
                    </button>
                </h2>
                <div
                    id="collapseOne"
                    class="!visible hidden"
                    data-te-collapse-item
                    // data-te-collapse-show
                    aria-labelledby="headingOne"
                    data-te-parent="#accordionExample">
                    <div class="py-4 px-5">
                        <div>
                            <div className='flex flex-col mb-2'>
                                <label className='font-semibold text-sm font-ubuntu'>Registration Number</label>
                                <label className='text-xs font-ubuntu'>4272</label>
                            </div>
                            <div className='flex flex-col mb-2'>
                                <label className='font-semibold text-sm font-ubuntu'>Engine Number</label>
                                <label className='text-xs font-ubuntu'>744849</label>
                            </div>
                            <div className='flex flex-col mb-2'>
                                <label className='font-semibold text-sm font-ubuntu'>Chase Number</label>
                                <label className='text-xs font-ubuntu'>74447ueie849</label>
                            </div>
                            <div className='flex flex-col mb-2'>
                                <label className='font-semibold text-sm font-ubuntu'>Vehicle Name</label>
                                <label className='text-xs font-ubuntu'>2023</label>
                            </div>
                            <div className='flex flex-col mb-2'>
                                <label className='font-semibold text-sm font-ubuntu'>Vehicle Modal</label>
                                <label className='text-xs font-ubuntu'>2023</label>
                            </div>
                            <div className='flex flex-col mb-2'>
                                <label className='font-semibold text-sm font-ubuntu'>Vehicle Images</label>
                                <div className='my-4'>
                                    <img className='rounded-md' src={sampleimage}/>
                                </div>
                            </div>

                            <div className='flex items-center justify-end'>
                                <button
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-sm bg-white px-3 py-1 text-sm text-black shadow-sm hover:bg-gray-100 sm:ml-3 sm:w-auto  border shadow-sm cursor-pointer font-roboto"
                                    onClick={() => navigateToVehicleDetailsScreen()}
                                >
                                    View
                                </button>
                                {/*<button
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-sm bg-theme_color-600 px-3 py-1 text-sm text-white shadow-sm hover:bg-theme_color-700 sm:ml-3 sm:w-auto cursor-pointer font-roboto"
                                    onClick={() => navigateToVehicleDetailsEditScreen()}
                                >
                                    Edit
    </button>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="border border-t-0 border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800">
                <h2 class="mb-0" id="headingTwo">
                    <button
                        class="group relative flex w-full items-center rounded-none border-0 bg-white p-3 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-teal [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-neutral-800 dark:[&:not([data-te-collapse-collapsed])]:text-teal dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)] font-ubuntu font-normal text-sm"
                        type="button"
                        data-te-collapse-init
                        data-te-collapse-collapsed
                        data-te-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo">
                        BMW 310R
                        <span
                            class="ml-auto -mr-1 h-5 w-5 shrink-0 rotate-[-180deg] fill-[#115e59] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:mr-0 group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#115e59] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="h-6 w-6">
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>
                        </span>
                    </button>
                </h2>
                <div
                    id="collapseTwo"
                    class="!visible hidden"
                    data-te-collapse-item
                    aria-labelledby="headingTwo"
                    data-te-parent="#accordionExample">
                    <div class="py-4 px-5">
                        <div>
                            <div className='flex flex-col mb-2'>
                                <label className='font-semibold text-sm font-ubuntu'>Registration Number</label>
                                <label className='text-xs font-ubuntu'>4272</label>
                            </div>
                            <div className='flex flex-col mb-2'>
                                <label className='font-semibold text-sm font-ubuntu'>Engine Number</label>
                                <label className='text-xs font-ubuntu'>744849</label>
                            </div>
                            <div className='flex flex-col mb-2'>
                                <label className='font-semibold text-sm font-ubuntu'>Chase Number</label>
                                <label className='text-xs font-ubuntu'>74447ueie849</label>
                            </div>
                            <div className='flex flex-col mb-2'>
                                <label className='font-semibold text-sm font-ubuntu'>Vehicle Name</label>
                                <label className='text-xs font-ubuntu'>2023</label>
                            </div>
                            <div className='flex flex-col mb-2'>
                                <label className='font-semibold text-sm font-ubuntu'>Vehicle Modal</label>
                                <label className='text-xs font-ubuntu'>2023</label>
                            </div>
                            <div className='flex flex-col mb-2'>
                                <label className='font-semibold text-sm font-ubuntu'>Vehicle Images</label>
                                <div className='my-4'>
                                    <img className='rounded-md' src={sampleimage}/>
                                </div>
                            </div>

                            <div className='flex items-center justify-end'>
                                <button
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-sm bg-white px-3 py-1 text-sm text-black shadow-sm hover:bg-gray-100 sm:ml-3 sm:w-auto mr-3 border shadow-sm cursor-pointer font-roboto"
                                    onClick={() => console.log()}
                                >
                                    View
                                </button>
                                <button
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-sm bg-theme_color-600 px-3 py-1 text-sm text-white shadow-sm hover:bg-theme_color-700 sm:ml-3 sm:w-auto cursor-pointer font-roboto"
                                    onClick={() => console.log()}
                                >
                                    Edit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccordionComponent