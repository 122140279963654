import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { useDispatch } from 'react-redux'
import { toggleFilter, updateFilter } from '../../redux/filter/index'

import { ReactComponent as FilterSVG } from '../../resouces/svg/filter.svg'
import { IconButton } from '@mui/material';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);
function LineChart() {
    const dispatch = useDispatch()

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Line Chart',
            },
        },
    };

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

    const data = {
        labels,
        datasets: [
            {
                fill: true,
                label: 'Dataset 2',
                data: [100, 40, 48, 473, 29, 191, 447],
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };
    return (
        <div className='p-2'>
            <div className='flex items-center justify-between'>
                <div className='text-center flex-grow text-sm text-base font-normal font-roboto'>INVOICE STATISTICS</div>
                <IconButton onClick={() => dispatch(toggleFilter({ component: "invoice_statistics" }))}>
                    <div className='filter-nofify'></div>
                    <FilterSVG style={{ color: "rgba(0,0,0,0.3)" }} />
                </IconButton>
            </div>
            <Line options={options} data={data} />
        </div>
    )
}

export default LineChart