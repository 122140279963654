import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import AccordionComponent from '../../component/ui_component/accordion'
import StepperComponent from '../../component/ui_component/stepper'
import TableComponent from '../../component/ui_component/table';
import DropDownComponent from '../../component/ui_component/dropdown'

function InvoiceAction() {
  const { setValue, control, register, formState: { errors }, handleSubmit } = useForm()
  const navigate = useNavigate()

  const onSubmit = (values) => {
    console.log(values)
  }

  const navigateToEditScreen = () => {
    navigate(`/invoice/989337/edit`)
  }

  const navigateToVehicleListScreen = () => {
    navigate(`/vendor/84984/details`)
  }

  const columns = [
    {
      name: 'S.NO',
      selector: (row, index) => index + 1,
    },
    {
      name: 'PRODUCT NAME',
      selector: row => row.product_name,
    },
    {
      name: 'PRICE',
      selector: row => row.product_price,
    },
    {
      name: 'QUANTITY',
      selector: row => row.product_quantity,
    },
    {
      name: 'TOTAL',
      selector: row => row.product_total,
    }
  ];

  const docs = [
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    },
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    },
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    },
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    },
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    },
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    },
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    },
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    },
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    },
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    }
  ]

  return (
    <div className='flex flex-col h-full'>
      <div className='sticky'>
        <div className='flex items-center justify-between'>
          <div className='h-14 font-semibold font-roboto'><span className='cursor-pointer' onClick={() => navigate("/invoice")}>Invoice</span> / Details</div>
          {/*<button
            type="submit"
            className="inline-flex w-full justify-center rounded-sm bg-theme_color-600 px-3 py-1 text-sm text-white shadow-sm hover:bg-theme_color-700 sm:ml-3 sm:w-auto mr-3 font-roboto"
            onClick={() => navigateToEditScreen()}
          >
            Edit
  </button>*/}
          <DropDownComponent/>
        </div>
      </div>
      <div className="p-3">
        <StepperComponent
          statusList={["Pending", "Accepted", "Cancelled"]}
        />
      </div>
      <div className='flex flex-col h-full w-full'>
        <div className='flex w-full p-2'>
          <div className='w-full'>
            <div className='flex justify-between items-start'>
              <div className='p-4 mr-3 box-card' style={{width: '65%'}}>
                <div className='text-lg font-normal mb-4 font-roboto'>Invoice Information</div>
                <div className='rounded-md bg-gray-100'>
                  <div className='flex items-center justify-between p-3'>
                    <span className='text-sm font-ubuntu'>Invoice No</span>
                    <span className='text-sm font-ubuntu font-semibold'>001</span>
                  </div>
                  <div className='flex items-center justify-between bg-gray-200 p-3'>
                    <span className='text-sm font-ubuntu'>Invoice Date</span>
                    <span className='text-sm font-ubuntu font-semibold'>04-05-2023</span>
                  </div>
                  <div className='flex items-center justify-between p-3'>
                    <span className='text-sm font-ubuntu'>Due Data</span>
                    <span className='text-sm font-ubuntu font-semibold'>05-05-2023</span>
                  </div>
                </div>
                <div className='items-start mt-8'>
                  <div className='text-lg mb-4 font-roboto font-normal'>Products</div>
                  <div className=''>
                  <TableComponent
                    column={columns}
                    docs={docs}
                    pagination={false}
                  />
                  </div>
                </div>
              </div>
              <div style={{width: '35%'}}>
                <div className='p-4 mb-3 box-card'>
                  <div className='flex items-center justify-between mb-4'>
                    <label className='text-lg font-roboto font-normal'>Customer</label>
                    <span className='text-md font-roboto font-normal cursor-pointer text-theme_color-800' onClick={() => navigateToVehicleListScreen()}>View</span>
                  </div>
                  <div className='px-2'>
                    <div className='font-ubuntu'>Kathiravan</div>
                    <div className='font-ubuntu text-gray-400'>kathiravandeveloper@gmail.com</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvoiceAction