import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

import { useDispatch } from 'react-redux'
import { toggleFilter, updateFilter } from '../../redux/filter/index'

import { ReactComponent as FilterSVG } from '../../resouces/svg/filter.svg'
import { IconButton } from '@mui/material';


ChartJS.register(ArcElement, Tooltip, Legend);
function InvoiceChart(props) {
  const dispatch = useDispatch()

  const data = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <div className='p-2'>
      <div className='flex items-center justify-between'>
        <div className='text-center flex-grow text-sm text-base font-normal font-roboto'>{props.name}</div>
        <IconButton onClick={() => dispatch(toggleFilter({ component: props.component_name }))}>
          <div className='filter-nofify'></div>
          <FilterSVG style={{ color: "rgba(0,0,0,0.3)" }} />
        </IconButton>
      </div>
      <Pie data={data} height={"100px"} width={"100px"} />
    </div>
  )
}

export default InvoiceChart