import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import AccordionComponent from '../../component/ui_component/accordion'

function CustomerDetailsPage() {
  const { setValue, control, register, formState: { errors }, handleSubmit } = useForm()
  const navigate = useNavigate()

  const onSubmit = (values) => {
    console.log(values)
  }

  const navigateToEditScreen = () => {
    navigate(`/customer/989337/edit`)
  }

  const navigateToVehicleListScreen = () => {
    navigate(`/customer/84984/vehicles`)
  }

  const navigateToVehicleDetailsScreen = () => {
    navigate(`/customer/84984/vehicle/48484993/details`)
  }

  const navigateToVehicleDetailsEditScreen = () => {
    navigate(`/customer/84984/vehicle/48484993/edit`)
  }

  return (
    <div className='flex flex-col h-full'>
      <div className='sticky'>
        <div className='flex items-center justify-between'>
          <div className='h-14 font-semibold font-roboto'><span className='cursor-pointer' onClick={() => navigate("/customer")}>Customer</span> / Details</div>
          <button
            type="submit"
            className="inline-flex w-full justify-center rounded-sm bg-theme_color-600 px-3 py-1 text-sm text-white shadow-sm hover:bg-theme_color-700 sm:ml-3 sm:w-auto mr-3 font-roboto"
            onClick={() => navigateToEditScreen()}
          >
            Edit
          </button>
        </div>
      </div>
      <div className='flex flex-col h-full w-full'>
        <div className='flex w-full p-2'>
          <div className='w-full'>
            <div className='flex justify-between items-start'>
              <div className='p-4 mr-3 box-card' style={{width: '65%'}}>
                <div className='text-lg font-normal mb-4 font-roboto'>Customer Information</div>
                <div className='rounded-md bg-gray-100'>
                  <div className='flex items-center justify-between p-3'>
                    <span className='text-sm font-ubuntu'>Customer Name</span>
                    <span className='text-sm font-ubuntu font-semibold'>Kathiravan V</span>
                  </div>
                  <div className='flex items-center justify-between bg-gray-200 p-3'>
                    <span className='text-sm font-ubuntu'>Customer Email</span>
                    <span className='text-sm font-ubuntu font-semibold'>kathiravandeveloper@gmail.com</span>
                  </div>
                  <div className='flex items-center justify-between p-3'>
                    <span className='text-sm font-ubuntu'>Customer Phone Number</span>
                    <span className='text-sm font-ubuntu font-semibold'>7550373257</span>
                  </div>
                  <div className='flex items-center justify-between p-3 bg-gray-200 rounded-b-md'>
                    <span className='text-sm font-ubuntu'>Primary Address</span>
                    <span className='text-sm font-ubuntu font-semibold'>242 Vinoba Street, Thalavai pettai, Bhavani, Erode 638312</span>
                  </div>
                  <div className='flex items-center justify-between p-3 rounded-b-md'>
                    <span className='text-sm font-ubuntu'>Secondary Address</span>
                    <span className='text-sm font-ubuntu font-semibold'>242 Vinoba Street, Thalavai pettai, Bhavani, Erode 638312</span>
                  </div>
                </div>
                <div className='items-start mt-8'>
                  <div className='text-lg mb-4 font-roboto font-normal'>Custom Fields</div>
                  <div className='grid grid-cols-2'>
                    <div className='mb-2 mr-4'>
                      <label className='text-md font-ubuntu font-normal'>
                        Customer Secondary Name
                      </label>
                      <div className='text-sm mt-1 font-ubuntu font-semibold'>Kathir V</div>
                    </div>
                    <div className='mb-2 mr-4'>
                      <label className='text-md font-ubuntu font-normal'>
                        Customer Suggestion
                      </label>
                      <div className='text-sm mt-1 font-ubuntu font-semibold'>Please do more focus on service related things.</div>
                    </div>
                    <div className='mb-2 mr-4'>
                      <label className='text-md font-ubuntu font-normal'>
                        Joined Date
                      </label>
                      <div className='text-sm mt-1 font-ubuntu font-semibold'>23/05/2023</div>
                    </div>
                    <div className='mb-2 mr-4'>
                      <label className='text-md font-ubuntu font-normal'>
                        Service Start Time
                      </label>
                      <div className='text-sm mt-1 font-ubuntu font-semibold'>11 PM</div>
                    </div>
                    <div className='mb-2 mr-4'>
                      <label className='text-md font-ubuntu font-normal'>
                        Customer Type
                      </label>
                      <div className='text-sm mt-1 font-ubuntu font-semibold'>Normal</div>
                    </div>
                    <div className='mb-2 mr-4'>
                      <label className='text-md font-ubuntu font-normal'>
                        Service Days
                      </label>
                      <div className='text-sm mt-1 font-ubuntu font-semibold'>Monday, Tuesday, Friday</div>
                    </div>
                    <div className='mb-2 mr-4'>
                      <label className='text-md font-ubuntu font-normal'>
                        Default Service Type
                      </label>
                      <div className='text-sm mt-1 font-ubuntu font-semibold'>Full Cleaning</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{width: '35%'}}>
                <div className='p-4 mb-3 box-card'>
                  <div className='flex items-center justify-between mb-4'>
                    <label className='text-lg font-roboto font-normal'>Vehicle List</label>
                    <span className='text-md font-roboto font-normal cursor-pointer text-theme_color-800' onClick={() => navigateToVehicleListScreen()}>View List</span>
                  </div>
                  <div>
                    <AccordionComponent
                      navigateToVehicleDetailsScreen={navigateToVehicleDetailsScreen}
                      navigateToVehicleDetailsEditScreen={navigateToVehicleDetailsEditScreen}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomerDetailsPage