import React, { useState } from 'react'
import InventoryAdd from './inventory.add'

function InventoryDetails() {
  const [actionType, setActionType] = useState('view')

  function toggleActionType(){
    if(actionType == "view"){
      setActionType('edit')
    }
    else if(actionType == "edit"){
      setActionType("view")
    }
  }

  function editInventory(values){
    console.log(values)
  }

  return (
    <InventoryAdd
      actionType={actionType}
      toggleActionType={toggleActionType}
      editInventory={editInventory}
    />
  )
}

export default InventoryDetails