import React, { useState, useRef, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import {useForm} from 'react-hook-form'

import {useSelector, useDispatch} from 'react-redux'

import {toggleFilter, updateFilter, clearFilter} from '../redux/filter/index'

// pages

import pages from "../pages/index.pages";
import AppBarLayout from "../layout/appbar.layout";
import SideBarLayout from "../layout/sidebar.layout";
import NotFound from "../pages/auth/404";

import LoginPage from '../pages/auth/login.page';
import RegisterPage from '../pages/auth/register.page';
import ForgotPasswordPage from '../pages/auth/forgot_password.page';
import DashboardPage from '../pages/home/dashboard.page';
import CustomerPage from '../pages/customer/customer.page'

import { ThemeColor } from "../utilities/theme.color";
import FilterMainComponent from "../component/filter";
import dayjs from "dayjs";
import CustomerAddOrEditPage from "../pages/customer/addoredit.customer.page";
import CustomerPageHandler from "../pages/customer";
import CustomerDetailsPage from "../pages/customer/details.customer.page";
import VehicleListComponent from "../pages/customer/vehicle/vehicle_list.page";
import VehicleDetailsPageComponent from "../pages/customer/vehicle/vehicle_details.page";
import VehicleAddOrEditPageComponent from "../pages/customer/vehicle/vehicle_addoredit.page";
import VendorListPage from "../pages/vendor/vendor.list";
import VendorAdd from "../pages/vendor/vendor.add";
import VendorDetails from "../pages/vendor/vendor.details";
import InventoryListPage from "../pages/inventory/inventory.list";
import InventoryDetails from "../pages/inventory/inventory.details";
import InventoryAdd from "../pages/inventory/inventory.add";
import PurchaseOrderList from "../pages/purchase_order/purchase_order.list";
import PurchaseOrderAdd from "../pages/purchase_order/purchase_order.add";
import PurchaseOrderDetails from "../pages/purchase_order/purchase_order.details";
import PurchaseOrderEdit from "../pages/purchase_order/purchase_order.edit";
import InvoiceList from "../pages/invoice/invoice.list";
import InvoiceAdd from "../pages/invoice/invoice.add";
import InvoiceEdit from "../pages/invoice/invoice.edit";
import InvoiceDetails from "../pages/invoice/invoice.details";




console.log(pages, "pages");

function checkIsUserLogged(){
  const user_details = localStorage.getItem('user')
  if(user_details){
    return true
  }
  return false
  // else{
  //   localStorage.setItem('user', JSON.stringify({name:'kathiravan', email: "kathiravandeveloper@gmail.com"}))
  //   return true
  // }
}

function UnProtectedRoute() {
  const usetoken = checkIsUserLogged()
  return (
    !usetoken ? <Outlet /> : <Navigate to="/" />
  )
}

function ProtectedRoute() {
  const dispatch = useDispatch()
  
  const [sideBarType, setSideBarType] = useState('max')
  const myDivRef = useRef(null);

  const filter = useSelector((state) => state.filter.value)

  const { register, handleSubmit, control, watch, formState: { errors } } = useForm({
    defaultValues: {
      filter_type: 'year',
      year: dayjs().format('YYYY')
    }
  });

  useEffect(() => {
    const element = document.getElementById('important-info-body-container')
    const element_important_info = document.getElementById("important-info-container")
    const backdrop_element = document.getElementById("backdrop")
    if(filter.open){
      if(element_important_info){
        element_important_info.style.display = "flex"
      }
      if(backdrop_element){
        backdrop_element.style.display = "block"
      }
      setTimeout(() => {
        element.style.right = '0px'
      }, 50)
    }else{
      element.style.right = '-320px'
      setTimeout(() => {
        if(element_important_info){
          element_important_info.style.display = "none"
        }
        if(backdrop_element){
          backdrop_element.style.display = "none"
        }
      }, 350)
    }
  }, [filter.open])

  const usetoken = checkIsUserLogged();

  function toggle_sidebar() {
    if (sideBarType == 'min') {
      setSideBarType("max")
    }
    else if (sideBarType == "max") {
      setSideBarType("min")
    }
  }

  const apply_filter = () => {
    dispatch(updateFilter({payload: {date: "2023-03-06", type: "year"}}))
    close_filter()
  }

  const reset_filter = () => {
    dispatch(clearFilter())
    close_filter()
  }

  const close_filter = () => {
    dispatch(toggleFilter())
  }

  const onSubmitFilter = (values) => {
    close_filter()
    dispatch(updateFilter({payload: {...values}}))
  }


  const side_bar_width = sideBarType == "min" ? "5.5%" : "15%"
  const body_width = sideBarType == "min" ? "94.5%" : "85%"

  return (
    usetoken ?
      (
        <>
          <div className='flex h-full w-full'>
            <div className="left-layout h-full" style={{width: side_bar_width}}>
              <SideBarLayout sideBarType={sideBarType} toggle_sidebar={toggle_sidebar} />
            </div>
            <div className="right-layout flex flex-col flex-grow" style={{width: body_width}}>
              <div style={{height: "10vh"}}>
                <AppBarLayout toggle_sidebar={toggle_sidebar} />
              </div>
              <div style={{height: "90vh"}} ref={myDivRef} className="body-layout flex-grow overflow-y-scroll overflow-x-hidden p-5 relative" id="body-layout">
                  <div className='w-full hidden' id='important-info-container'>
                      <div className='flex flex-grow relative z-10'>
                        <div className='backdrop flex-grow hidden w-full fixed' style={{height: "90vh"}} id='backdrop' onClick={() => dispatch(toggleFilter())}>
                        </div>
                        <div className='w-full bg-white-600 shadow-xl' id='important-info-body-container' style={{backgroundColor: "#f9fafb", height: "90vh"}}>
                          <form className="h-full" onSubmit={handleSubmit(onSubmitFilter)}>
                            <div className="filter-body" style={{height: '90%'}}>
                              <FilterMainComponent register={register} control={control}/>
                            </div>  
                            <div className="filter-footer">
                              <div className='flex items-center justify-between p-5 h-14'>
                                <button class="bg-theme_color-600 hover:bg-theme_color-700 text-white text-sm py-1 px-4 border border-theme_color-700 rounded" type="submit">
                                  Apply
                                </button>
                                <button class="bg-white hover:bg-gray-100 text-gray-800 py-1 px-4 border text-sm border-gray-400 rounded shadow" type="button" onClick={() => reset_filter()}>
                                  Reset
                                </button>
                                <button class="bg-white hover:bg-red-100 text-red-800 py-1 px-4 border text-sm border-red-400 rounded shadow" type="button" onClick={() => close_filter()}>
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                  </div>
                  <Outlet />
              </div>
            </div>
          </div>
        </>
      )
      : <Navigate to="/login" />
  )
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<UnProtectedRoute />}>
        <Route path="/login" element={<LoginPage />} exact />
        <Route path="/register" element={<RegisterPage />} exact />
        <Route
          path="/forgot-password"
          element={<ForgotPasswordPage />}
          exact
        />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<DashboardPage />} exact />
        <Route path="/customer" element={<CustomerPageHandler/>} exact />
        <Route path="/customer/add" element={<CustomerAddOrEditPage/>} exact />
        <Route path="/customer/:customer_id/details" element={<CustomerDetailsPage/>} exact />
        <Route path="/customer/:customer_id/edit" element={<CustomerAddOrEditPage/>} exact />
        <Route path="/customer/:customer_id/vehicles" element={<VehicleListComponent/>} exact />
        <Route path="/customer/:customer_id/vehicle/:vehicle_id/details" element={<VehicleDetailsPageComponent/>} exact />
        <Route path="/customer/:customer_id/vehicle/add" element={<VehicleAddOrEditPageComponent/>} exact />
        <Route path="/vendor" element={<VendorListPage/>} exact />
        <Route path="/vendor/add" element={<VendorAdd/>} exact />
        <Route path="/vendor/:vendor_id/details" element={<VendorDetails/>} exact />
        <Route path="/inventory" element={<InventoryListPage/>} exact />
        <Route path="/inventory/:inventory_id/details" element={<InventoryDetails/>} exact />
        <Route path="/inventory/add" element={<InventoryAdd/>} exact />
        <Route path="/purchase-order" element={<PurchaseOrderList/>} exact />
        <Route path="/purchase-order/add" element={<PurchaseOrderAdd/>} exact />
        <Route path="/purchase-order/:purchase_order_id/details" element={<PurchaseOrderDetails/>} exact />
        <Route path="/purchase-order/:purchase_order_id/edit" element={<PurchaseOrderEdit/>} exact />
        <Route path="/invoice" element={<InvoiceList/>} exact />
        <Route path="/invoice/add" element={<InvoiceAdd/>} exact />
        <Route path="/invoice/:id/edit" element={<InvoiceEdit/>} exact />
        <Route path="/invoice/:id/details" element={<InvoiceDetails/>} exact />
      </Route>
      <Route path='*' element={<NotFound />} />
    </>
  )
);

function RouteLayout() {
  return (
    <RouterProvider router={router} />
    // <BrowserRouter>
    //     <Routes>
    //         <Route element={<UnProtectedRoute />}>
    //             <Route path="/login" element={<LoginPage />} exact />
    //             <Route path="/register" element={<RegisterPage />} exact />
    //             <Route
    //                 path="/forgot-password"
    //                 element={<ForgotPasswordPage />}
    //                 exact
    //             />
    //         </Route>
    //         <Route element={<ProtectedRoute />}>
    //             <Route path="/" element={<DashboardPage />} exact />
    //         </Route>
    //         <Route path='*' element={<NotFound/>}/>
    //     </Routes>
    // </BrowserRouter>
  );
}

export default RouteLayout;
