import React, { useState, Fragment } from 'react'
import {Controller, useController} from 'react-hook-form'

import { useSelector, useDispatch } from 'react-redux'
import {updateFilter, clearFilter, toggleFilter} from '../../redux/filter/index'

import Select from 'react-select'

import DateComponent from '../ui_component/date'
import DropDownSelect from '../ui_component/dropdown_select'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function PurchaseAndSalesFilter(props) {
  const dispatch = useDispatch()
  const {register, control} = props
  const {filterComponentName = ""} = props

  const filterOptions = [
    { value: 'year', label: 'Year' },
    { value: 'month', label: 'Month' },
    { value: 'custom', label: 'Custom' }
  ]

  const [filterType, setFilterType] = useState({ value: 'year', label: 'Year' })
  const [selectedYear, setSelectedYear] = useState(new Date())
  const [selectedFromDate, setSelectedFromDate] = useState(new Date())
  const [selectedToDate, setSelectedToDate] = useState(new Date())

  function generateComponent(){
    if(filterType.value == "month"){
      return (
        <>
          <div className='py-1 '>
            <div className="mb-2 text-sm">Select Year</div>
            <DateComponent 
              setSelectedDate={setSelectedYear} 
              selectedDate={selectedYear} 
              showYearPicker={true} 
              dateFormat="yyyy"
              control={control}
              name={"year"}
              register={register}
            />
          </div>
        </>
        )
      }
    else if(filterType.value == "custom"){
      return (
        <>
          <div className='py-1'>
            <div className="mb-2 text-sm">From Date</div>
            <DateComponent 
              setSelectedDate={setSelectedFromDate} 
              selectedDate={selectedFromDate}
              control={control}
              name={"from_date"}
              register={register}
            />
          </div>

          <div className='py-1'>
            <div className="mb-2 text-sm">To Date</div>
            <DateComponent 
              setSelectedDate={setSelectedToDate} 
              selectedDate={selectedToDate}
              control={control}
              name={"to_date"}
              register={register}
            />
          </div>
        </>
        )
    }
    else if(filterType.value == "year"){
      return (
        <>
        </>
        )
    }
  }

  return (
    <div className='relative flex flex-col h-full'>
      <div className='p-5 shadow-sm flex-grow'>
        <div className='py-1'>
          <div className="mb-2 text-sm">Filter Type</div>
          <DropDownSelect 
            register={register} 
            options={filterOptions} 
            defaultValue={filterType} 
            setSelected={setFilterType} 
            control={control}
            name={"filter_type"}
          />
        </div>
        {
          generateComponent()
        }
      </div>
    </div>
  )
}

export default PurchaseAndSalesFilter