import React, { useEffect, useState, useRef, useCallback } from 'react'

import SalesChart from '../../component/charts/sales.chart'
import InvoiceChart from '../../component/charts/invoice.chart'
import LineChart from '../../component/charts/line.chart'


function DashboardPage(props) {

  return (
    <>
      <div>
        {/*<div className='w-full mb-4 flex items-center justify-between'>
          <div className='shadow-md rounded-md p-3 flex flex-col items-center h-24 w-44'>
            <div className='font-semibold'>Total Customers</div>
            <div className='flex-grow flex items-center justify-center font-semibold text-theme_color-700 text-xl'>58</div>
          </div>
  </div>*/}
        <div className='grid grid-cols-2' style={{gridGap: '10px'}}>
          <div className='p-2 box-card'>
            <SalesChart/>
          </div>
          <div className=''>
            <div className='grid grid-cols-2 h-full' style={{gridGap: '10px'}}>
              <div className='flex flex-col p-2 box-card'>
                <div className='text-sm text-base font-normal mb-3 font-roboto'>SERVICE REQUEST STATISTICS</div>
                <div className='p-2 flex flex-col w-full'>
                  <div className='p-2 flex items-center justify-between'>
                    <div className='text-sm text-base'>Total Request</div>
                    <div className='text-md text-base font-semibold'>49</div>
                  </div>
                  <div className='p-2 flex items-center justify-between'>
                    <div className='text-sm text-base'>In Progress</div>
                    <div className='text-md text-base font-semibold'>10</div>
                  </div>
                  <div className='p-2 flex items-center justify-between'>
                    <div className='text-sm text-base'>Cancelled</div>
                    <div className='text-md text-base font-semibold'>10</div>
                  </div>
                  <div className='p-2 flex items-center justify-between'>
                    <div className='text-sm text-base'>Incompleted</div>
                    <div className='text-md text-base font-semibold'>1</div>
                  </div>
                  <div className='p-2 flex items-center justify-between'>
                    <div className='text-sm text-base'>Completed</div>
                    <div className='text-md text-base font-semibold'>20</div>
                  </div>
                </div>
              </div>
              <div className='box-card p-2'>
                <div className='text-sm text-base font-normal mb-3 font-roboto'>SALES INVOICE STATISTICS</div>
                <div className='p-2 flex flex-col w-full'>
                  <div className='p-2 flex items-center justify-between'>
                    <div className='text-sm text-base'>Total Request</div>
                    <div className='text-md text-base font-semibold'>49</div>
                  </div>
                  <div className='p-2 flex items-center justify-between'>
                    <div className='text-sm text-base'>In Progress</div>
                    <div className='text-md text-base font-semibold'>10</div>
                  </div>
                  <div className='p-2 flex items-center justify-between'>
                    <div className='text-sm text-base'>Cancelled</div>
                    <div className='text-md text-base font-semibold'>10</div>
                  </div>
                  <div className='p-2 flex items-center justify-between'>
                    <div className='text-sm text-base'>Incompleted</div>
                    <div className='text-md text-base font-semibold'>1</div>
                  </div>
                  <div className='p-2 flex items-center justify-between'>
                    <div className='text-sm text-base'>Completed</div>
                    <div className='text-md text-base font-semibold'>20</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='grid grid-cols-3 mt-3' style={{gridGap: '10px'}}>
          <div className='p-2 box-card'>
            <InvoiceChart name="SALES INVOICE STATISTICS" component_name="sales_invoice_statistics"/>
          </div>
          <div className='p-2 box-card'>
            <InvoiceChart name="PURCHASE INVOICE STATISTICS" component_name="purchase_invoice_statistics"/>            
          </div>
          <div>
            <div className='box-card p-2'>
              <div className='text-sm text-base font-noraml mb-3 font-roboto'>PURCHASE INVOICE STATISTICS</div>
              <div className='p-2 flex flex-col w-full'>
                <div className='p-2 flex items-center justify-between'>
                  <div className='text-sm text-base'>Total Request</div>
                  <div className='text-md text-base font-semibold'>49</div>
                </div>
                <div className='p-2 flex items-center justify-between'>
                  <div className='text-sm text-base'>In Progress</div>
                  <div className='text-md text-base font-semibold'>10</div>
                </div>
                <div className='p-2 flex items-center justify-between'>
                  <div className='text-sm text-base'>Cancelled</div>
                  <div className='text-md text-base font-semibold'>10</div>
                </div>
                <div className='p-2 flex items-center justify-between'>
                  <div className='text-sm text-base'>Incompleted</div>
                  <div className='text-md text-base font-semibold'>1</div>
                </div>
                <div className='p-2 flex items-center justify-between'>
                  <div className='text-sm text-base'>Completed</div>
                  <div className='text-md text-base font-semibold'>20</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='grid grid-cols-1 mt-4' style={{gridGap: '10px'}}>
          <div className='p-2 box-card'>
            <LineChart/>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashboardPage