import { IconButton } from '@mui/material'
import React, { useLayoutEffect, useState, Fragment, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import CustomFieldComponent from '../../component/ui_component/customfield'
import DropDownSelect from '../../component/ui_component/dropdown_select'
import VehicleModalComponent from '../../component/ui_component/vehicle.modal'
import { Dialog, Transition } from '@headlessui/react'
import ImageUploadComponent from '../../component/ui_component/image_upload'

import { ReactComponent as PlusIcon } from '../../resouces/svg/plus.svg'

function InventoryAdd(props) {
  const navigate = useNavigate()
  const { register, control, handleSubmit, formState: { errors } } = useForm()
  const [categoryList, setCategoryList] = useState([{ label: "General", value: "general" }, { label: "VIB", value: 'vib' }])
  const [customFieldList, setCustomFieldList] = useState([])
  let [isOpen, setIsOpen] = useState(false)
  const [attachments, setAttachments] = useState([])

  let component = props.Component || 'inventory_add'

  const [actionType, setActionType] = useState('add')

  const url = window.location.href

  console.log(url, 'url')

  useLayoutEffect(() => {
    const split = url.split('/')
    if (split.includes('edit')) {
      setActionType('edit')
    }
  }, [url])

  useEffect(() => {
    if(props.actionType){
      setActionType(props.actionType)
    }
  }, [props.actionType])

  const onSubmit = (values) => {
    console.log(values, 'values 1')
    console.log(customFieldList, 'custom_field list')

    const payload = {
      billing_address: {
        street: values.billing_address_street,
        city: values.billing_address_city,
        state: values.billing_address_state,
        country: values.billing_address_country,
        zip_code: values.billing_address_zipcode
      },
      service_address: {
        street: values.service_address_street,
        city: values.service_address_city,
        state: values.service_address_state,
        country: values.service_address_country,
        zip_code: values.service_address_zipcode
      },
      inventory_first_name: values.inventory_first_name,
      inventory_last_name: values.inventory_last_name,
      inventory_no: values.inventory_no,
      inventory_brand_name: values.inventory_brand_name,
      custom_category: values.inventory_category
    }

    if(actionType == "edit"){
      props.editInventory(payload)
    }
    else{
      addInventory(payload)
    }
  }

  function addInventory(values){
    console.log('add inventory', values)
  }

  const addCustomField = (values) => {
    const payload = {
      name: values.field_name,
      type: values.field_type,
      placeholder: values.placeholder,
      description: values.description,
      options: values.options ? values.options.split('\n') : [],
      hidden: values.hidden_field,
      visible_only_admin: values.visible_only_admin,
      index: customFieldList.length + 1
    }
    setCustomFieldList([...customFieldList, payload])
  }

  const editCustomField = (values, prev_values) => {
    console.log({ values, prev_values })
    const findIndex = customFieldList.findIndex(x => x.index == prev_values.index)
    const clone = customFieldList
    const payload = {
      name: values.field_name,
      type: values.field_type.value ? values.field_type.value : values.field_type,
      placeholder: values.placeholder,
      description: values.description,
      options: values.options ? values.options.split('\n') : [],
      hidden: values.hidden_field,
      visible_only_admin: values.visible_only_admin,
      index: prev_values.index
    }
    if (findIndex > -1) {
      clone[findIndex] = payload
    } else {
      clone.push({ ...payload, index: customFieldList.length + 1 })
    }
    console.log(clone, 'clone')
    setCustomFieldList([...clone])
  }

  const deleteCustomField = (field) => {
    const clone = [...customFieldList]
    clone.splice(field.index, 1)
    setCustomFieldList(clone)
    deleteCustomField(field)
  }

  function elementDisable(){
    if(actionType == "view"){
      return true
    }
    return false
  }

  return (
    <div className='flex flex-col h-full'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='sticky'>
          <div className='flex items-center justify-between'>
            <div className='h-14 font-semibold font-roboto'><span className='cursor-pointer' onClick={() => navigate("/inventory")}>Inventory</span> {actionType == "add" ? <span>/ New</span> : <span className='cursor-pointer' onClick={() => navigate("/inventory/97849/details")}>/ Break</span>} {actionType == "edit" && "/ Edit"} </div>
            <div>
              {actionType == "edit" && (
                <React.Fragment>
                  <button
                    type="submit"
                    className="font-roboto inline-flex w-full justify-center rounded-sm bg-theme_color-600 px-3 py-1 text-sm text-white shadow-sm hover:bg-theme_color-700 sm:ml-3 sm:w-auto mr-3"
                  // onClick={() => setProperty()}
                  >
                    {actionType == "add" ? "Create" : actionType == "edit" ? "Update" : ""}
                  </button>
                  <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-sm bg-white px-3 py-1 text-sm text-black shadow-sm hover:bg-gray-100 sm:ml-3 sm:w-auto  border shadow-sm cursor-pointer font-roboto"
                      onClick={() => setActionType('view')}
                  >
                      Cancel
                  </button>
                </React.Fragment>
              )}
              {actionType == "view" && (
                <button
                  type="button"
                  className="font-roboto inline-flex w-full justify-center rounded-sm bg-theme_color-600 px-3 py-1 text-sm text-white shadow-sm hover:bg-theme_color-700 sm:ml-3 sm:w-auto mr-3"
                onClick={() => setActionType('edit')}
                >
                  Edit
                </button>
              )}
              
            </div>
          </div>
        </div>
        <div className='flex flex-col h-full'>
          <div className='p-2 flex items-start justify-between'>
            <div className='box-card mr-2 p-4' style={{ width: '70%' }}>
              <div className='grid grid-cols-2 gap-3'>
                <div className='mb-2'>
                  <label htmlFor="inventory-name" className='text-sm font-ubuntu'>
                    Inventory Name
                  </label>
                  <input
                    id="inventory-name"
                    name="inventory_name"
                    type="text"
                    disabled={elementDisable()}
                    {...register("inventory_name", { required: true })}
                    autoComplete="inventory_name"
                    className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.inventory_name ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.inventory_name ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.inventory_name ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.inventory_name && "error-input"}`}
                    placeholder=""
                  />
                  {errors.inventory_name && <span className='text-xs text-red-700'>This field is required</span>}
                </div>
                <div className='mb-2'>
                  <label htmlFor="inventory-no" className='text-sm'>
                    Inventory no
                  </label>
                  <input
                    id="inventory-no"
                    name="inventory_no"
                    type="text"
                    disabled={elementDisable()}
                    {...register("inventory_no", { required: true })}
                    autoComplete="inventory_no"
                    className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.inventory_no ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.inventory_no ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.inventory_no ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.inventory_no && "error-input"}`}
                    placeholder=""
                  />
                  {errors.inventory_no && <span className='text-xs text-red-700'>This field is required</span>}
                </div>
                <div className='mb-2'>
                  <label htmlFor="inventory-brand-name" className='text-sm font-ubuntu'>
                    Inventory Brand Name
                  </label>
                  <input
                    id="inventory-brand-name"
                    name="inventory_brand_name"
                    type="text"
                    disabled={elementDisable()}
                    {...register("inventory_brand_name", { required: true })}
                    autoComplete="inventory_brand_name"
                    className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.inventory_brand_name ? "border-red-700" : `border-gray-500`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.inventory_brand_name ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.inventory_brand_name ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.inventory_brand_name && "error-input"}`}
                    placeholder=""
                  />
                  {errors.inventory_brand_name && <span className='text-xs text-red-700'>This field is required</span>}
                </div>
                <div className='mb-2'>
                  <label htmlFor="inventory-category" className='text-sm font-ubuntu'>
                    Inventory Category
                  </label>
                  <DropDownSelect
                    register={register}
                    options={categoryList}
                    defaultValue={categoryList[0]}
                    setSelected={(e) => { console.log(e, 'e') }}
                    control={control}
                    name={"inventory_category"}
                    isDisabled={elementDisable()}
                  />
                  {errors.inventory_category && <span className='text-xs text-red-700'>This field is required</span>}
                </div>
                <div className='mb-2'>
                  <label htmlFor="inventory-type" className='text-sm font-ubuntu'>
                    Inventory Type
                  </label>
                  <input
                    id="inventory-type"
                    name="inventory_type"
                    type="text"
                    disabled={elementDisable()}
                    {...register("inventory_type", { required: true })}
                    autoComplete="inventory_type"
                    className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.inventory_brand_name ? "border-red-700" : `border-gray-500`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.inventory_brand_name ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.inventory_brand_name ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.inventory_brand_name && "error-input"}`}
                    placeholder=""
                  />
                  {errors.inventory_type && <span className='text-xs text-red-700'>This field is required</span>}
                </div>
                <div className='mb-2'>
                  <label htmlFor="inventory-nature" className='text-sm font-ubuntu'>
                    Inventory Nature
                  </label>
                  <input
                    id="inventory-nature"
                    name="inventory_nature"
                    type="text"
                    disabled={elementDisable()}
                    {...register("inventory_nature", { required: true })}
                    autoComplete="inventory_nature"
                    className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.inventory_brand_name ? "border-red-700" : `border-gray-500`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.inventory_brand_name ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.inventory_brand_name ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.inventory_brand_name && "error-input"}`}
                    placeholder=""
                  />
                  {errors.inventory_nature && <span className='text-xs text-red-700'>This field is required</span>}
                </div>
                <div className='mb-2'>
                  <label htmlFor="unit-selling-price" className='text-sm font-ubuntu'>
                    Unit Selling Price
                  </label>
                  <input
                    id="unit-selling-price"
                    name="unit_selling_price"
                    type="text"
                    disabled={elementDisable()}
                    {...register("unit_selling_price", { required: true })}
                    autoComplete="unit_selling_price"
                    className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.inventory_brand_name ? "border-red-700" : `border-gray-500`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.inventory_brand_name ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.inventory_brand_name ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.inventory_brand_name && "error-input"}`}
                    placeholder=""
                  />
                  {errors.unit_selling_price && <span className='text-xs text-red-700'>This field is required</span>}
                </div>
                <div className='mb-2'>
                  <label htmlFor="unit-purchase-price" className='text-sm font-ubuntu'>
                    Unit Purchase Price
                  </label>
                  <input
                    id="unit-purchase-price"
                    name="unit_purchase_price"
                    type="text"
                    disabled={elementDisable()}
                    {...register("unit_purchase_price", { required: true })}
                    autoComplete="unit_purchase_price"
                    className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.inventory_brand_name ? "border-red-700" : `border-gray-500`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.inventory_brand_name ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.inventory_brand_name ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.inventory_brand_name && "error-input"}`}
                    placeholder=""
                  />
                  {errors.unit_purchase_price && <span className='text-xs text-red-700'>This field is required</span>}
                </div>
                <div className='mb-2'>
                  <label htmlFor="inventory-specification" className='text-sm font-ubuntu'>
                    Inventory Specification
                  </label>
                  <input
                    id="inventory-specification"
                    name="inventory_specification"
                    type="text"
                    disabled={elementDisable()}
                    {...register("inventory_specification", { required: true })}
                    autoComplete="inventory_specification"
                    className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.inventory_brand_name ? "border-red-700" : `border-gray-500`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.inventory_brand_name ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.inventory_brand_name ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.inventory_brand_name && "error-input"}`}
                    placeholder=""
                  />
                  {errors.inventory_specification && <span className='text-xs text-red-700'>This field is required</span>}
                </div>
                <div className='mb-2'>
                  <label htmlFor="inventory-tax" className='text-sm font-ubuntu'>
                    Inventory Tax
                  </label>
                  <DropDownSelect
                    register={register}
                    options={categoryList}
                    defaultValue={categoryList[0]}
                    setSelected={(e) => { console.log(e, 'e') }}
                    control={control}
                    name={"inventory_tax"}
                    multiple={true}
                    isDisabled={elementDisable()}
                  />
                  {errors.inventory_tax && <span className='text-xs text-red-700'>This field is required</span>}
                </div>
                <div className='mb-2'>
                  <label htmlFor="inventory-location" className='text-sm font-ubuntu'>
                    Inventory Location
                  </label>
                  <DropDownSelect
                    register={register}
                    options={categoryList}
                    defaultValue={categoryList[0]}
                    setSelected={(e) => { console.log(e, 'e') }}
                    control={control}
                    name={"inventory_location"}
                    isDisabled={elementDisable()}
                    // multiple={true}
                  />
                  {errors.inventory_location && <span className='text-xs text-red-700'>This field is required</span>}
                </div>
              </div>
              <ImageUploadComponent
                setImageList={setAttachments} 
                attachments={attachments} 
                disabled={elementDisable()}
                actionType={actionType}
                component={component}
              />
              <div className='mt-3'>
                <CustomFieldComponent
                  register={register}
                  control={control}
                  customFieldList={customFieldList}
                  setCustomFieldList={setCustomFieldList}
                  addCustomField={addCustomField}
                  editCustomField={editCustomField}
                  deleteCustomField={deleteCustomField}
                  identifireComponentName={"inventory"}
                  index={0}
                />
              </div>
            </div>
            <div style={{ width: '30%' }}>
              <div className='p-4 box-card mb-3'>
                <div className='flex items-center justify-between'>
                  <div className='font-normal font-roboto'>Inventory Location</div>
                  {/*<span className='cursor-pointer'>
                    <IconButton style={{ backgroundColor: 'rgba(0,0,0,0.1)' }} onClick={() => {
                    }}>
                      <PlusIcon style={{ height: 20, width: 20 }} />
                    </IconButton>
                  </span>*/}
                </div>
                <div className='h-full'>

                </div>
              </div>
              <div className='p-4 box-card'>
                <div className='font-roboto'>Associations <span className='text-sm text-gray-400'>(Comming Soon)</span></div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default InventoryAdd