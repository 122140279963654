import { useEffect, useState } from 'react'
import { Dialog } from '@headlessui/react'
import { useForm } from 'react-hook-form'
// import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import DropDownSelect from './dropdown_select'
import CustomFieldComponent from './customfield'
import ImageUploadComponent from './image_upload'

function VehicleModalComponent({ 
  setProperty, 
  modal, 
  AddVehicle, 
  selectedVehicle, 
  EditVehicle, 
  vehicleList, 
  cancelButtonRef, 
  component: Component, 
  toggleActionType,
  actionType
}) {
  const { register, control, handleSubmit, setValue, formState: { errors } } = useForm()
  const [vehicleTypeList, setFieldVehicleTypeList] = useState([
    { label: "Bike", value: "bike" },
    { label: "Car", value: "car" },
    { label: "Truck", value: "truck" },
  ])
  const [customFieldList, setCustomFieldList] = useState([])
  const [attachments, setAttachments] = useState([])

  const type = selectedVehicle ? "edit" : "add"

  console.log(Component, 'Component')
  let component = Component || getComponentName()

  function getComponentName(){
    const url = window.location.href
    console.log(url)
    return ""
  }

  useEffect(() => {
    // setOpen(modal)
    if (selectedVehicle) {
      setValue('registration_number', selectedVehicle.registration_number)
      setValue('engine_number', selectedVehicle.engine_number)
      setValue('chase_number', selectedVehicle.chase_number)
      setValue('vehicle_name', selectedVehicle.vehicle_name)
      setValue('vehicle_model', selectedVehicle.vehicle_model)
      setValue('insurrence_company_name', selectedVehicle.insurrence_company_name)
      setValue('insurrence_policy_name', selectedVehicle.insurrence_policy_name)
      setValue('insurrence_expiry_date', selectedVehicle.insurrence_expiry_date)
      setValue('insurrence_name', selectedVehicle.insurrence_name)
      setValue('insurrence_owner_name', selectedVehicle.insurrence_owner_name)
      setValue('insurrence_driver_name', selectedVehicle.insurrence_driver_name)
      setValue('vehicle_category', vehicleTypeList.find(x => x.value == selectedVehicle.vehicle_category)?.value || "")
      setAttachments(selectedVehicle.attachments)
    } else {
      setValue('registration_number', "")
      setValue('engine_number', "")
      setValue('chase_number', "")
      setValue('vehicle_name', "")
      setValue('vehicle_model', "")
      setValue('insurrence_company_name', "")
      setValue('insurrence_policy_name', "")
      setValue('insurrence_expiry_date', "")
      setValue('insurrence_name', "")
      setValue('insurrence_owner_name', "")
      setValue('insurrence_driver_name', "")
      setValue('vehicle_category', "")

    }
  }, [modal, selectedVehicle])

  const onSubmit = (values) => {
    values['attachments'] = attachments
    values['custom_fields'] = customFieldList
    console.log(values, 'values')
    if (selectedVehicle) {
      return EditVehicle(values, selectedVehicle)
    }
    AddVehicle(values)
  }

  const addCustomField = (values) => {
    const payload = {
      name: values.field_name,
      type: values.field_type,
      placeholder: values.placeholder,
      description: values.description,
      options: values.options ? values.options.split('\n') : [],
      hidden: values.hidden_field,
      visible_only_admin: values.visible_only_admin,
      index: customFieldList.length + 1
    }
    setCustomFieldList([...customFieldList, payload])
  }

  const editCustomField = (values, prev_values) => {
    console.log({ values, prev_values })
    const findIndex = customFieldList.findIndex(x => x.index == prev_values.index)
    const clone = customFieldList
    const payload = {
      name: values.field_name,
      type: values.field_type.value ? values.field_type.value : values.field_type,
      placeholder: values.placeholder,
      description: values.description,
      options: values.options ? values.options.split('\n') : [],
      hidden: values.hidden_field,
      visible_only_admin: values.visible_only_admin,
      index: prev_values.index
    }
    if (findIndex > -1) {
      clone[findIndex] = payload
    } else {
      clone.push({ ...payload, index: customFieldList.length + 1 })
    }
    console.log(clone, 'clone')
    setCustomFieldList([...clone])
  }

  const deleteCustomField = (field) => {
    const clone = [...customFieldList]
    clone.splice(field.index, 1)
    setCustomFieldList(clone)
    deleteCustomField(field)
  }

  function elementDisable(){
    if(component != "customer_vehicle" && actionType == "view"){
      return true
    }
    return false
  }

  function showButton(){
    if(component != "customer_vehicle"){
        if(actionType == "edit") return true
        else return false
    }
    return true
} 

  return (
    <form>
      <div className={`bg-white w-full ${component != "customer_vehicle" && "p-5 box-card"}`}>
        <div className="sm:flex sm:items-start w-full">
          <div className="mt-3 text-center sm:mt-0 sm:ml-0 sm:text-left w-full">
            {component != "customer_vehicle" && 
            <div className='flex items-center justify-between'>
              <div className="text-base font-medium text-gray-900 font-roboto">
                {component == "add_vehicle" ? "NEW VEHICLE" : "VEHICLE DETAILS"}
              </div>
              {component != "customer_vehicle" && component != "add_vehicle" && <div>
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-sm bg-theme_color-600 px-3 py-1 text-sm text-white shadow-sm hover:bg-theme_color-700 sm:ml-3 sm:w-auto"
                  onClick={() => toggleActionType()}
                >
                  Edit
                </button>
              </div>}
            </div>
            }
            <div className="mt-4 grid grid-cols-2 gap-2">
              <div className='mb-2'>
                <label htmlFor="registration-number" className='text-sm font-ubuntu'>
                  Registration Number
                </label>
                <input
                  id="registration-number"
                  name="registration_number"
                  type="text"
                  disabled={elementDisable()}
                  {...register("registration_number", { required: true })}
                  autoComplete="registration_number"
                  className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.registration_number ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.registration_number ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.registration_number ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.registration_number && "error-input"}`}
                  placeholder=""
                />
                {errors.registration_number && <span className='text-xs text-red-700'>This field is required</span>}
              </div>
              <div className='mb-2'>
                <label htmlFor="engine-number" className='text-sm font-ubuntu'>
                  Engine Number
                </label>
                <input
                  id="engine-number"
                  name="engine_number"
                  type="text"
                  disabled={elementDisable()}
                  {...register("engine_number", { required: false })}
                  autoComplete="engine_number"
                  className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.engine_number ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.engine_number ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.engine_number ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.engine_number && "error-input"}`}
                  placeholder=""
                />
                {errors.engine_number && <span className='text-xs text-red-700'>This field is required</span>}
              </div>
              <div className='mb-2'>
                <label htmlFor="chase-number" className='text-sm font-ubuntu'>
                  Chase Number
                </label>
                <input
                  id="chase-number"
                  name="chase_number"
                  type="text"
                  disabled={elementDisable()}
                  {...register("chase_number", { required: false })}
                  autoComplete="chase_number"
                  className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.chase_number ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.chase_number ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.chase_number ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.chase_number && "error-input"}`}
                  placeholder=""
                />
                {errors.chase_number && <span className='text-xs text-red-700'>This field is required</span>}
              </div>
              <div className='mb-2'>
                <label htmlFor="vehicle-name" className='text-sm font-ubuntu'>
                  Vehicle Name
                </label>
                <input
                  id="vehicle-name"
                  name="vehicle_name"
                  type="text"
                  disabled={elementDisable()}
                  {...register("vehicle_name", { required: false })}
                  autoComplete="description"
                  className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.vehicle_name ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.vehicle_name ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.vehicle_name ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.vehicle_name && "error-input"}`}
                  placeholder=""
                />
                {errors.vehicle_name && <span className='text-xs text-red-700'>This field is required</span>}
              </div>
              <div className='mb-2'>
                <label htmlFor="vehicle-model" className='text-sm font-ubuntu'>
                  Vehicle Model
                </label>
                <input
                  id="vehicle-model"
                  name="vehicle_model"
                  type="text"
                  disabled={elementDisable()}
                  {...register("vehicle_model", { required: false })}
                  autoComplete="description"
                  className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.vehicle_model ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.vehicle_model ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.vehicle_model ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.vehicle_model && "error-input"}`}
                  placeholder=""
                />
                {errors.vehicle_model && <span className='text-xs text-red-700'>This field is required</span>}
              </div>
              <div className='mb-2'>
                <label htmlFor="insurrence-company-name" className='text-sm font-ubuntu'>
                  Insurrence Company Name
                </label>
                <input
                  id="insurrence-company-name"
                  name="insurrence_company_name"
                  type="text"
                  disabled={elementDisable()}
                  {...register("insurrence_company_name", { required: false })}
                  autoComplete="description"
                  className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.insurrence_company_name ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.insurrence_company_name ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.insurrence_company_name ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.insurrence_company_name && "error-input"}`}
                  placeholder=""
                />
                {errors.insurrence_company_name && <span className='text-xs text-red-700'>This field is required</span>}
              </div>
              <div className='mb-2'>
                <label htmlFor="insurrence-policy-name" className='text-sm font-ubuntu'>
                  Insurrence Policy Name
                </label>
                <input
                  id="insurrence-policy-name"
                  name="insurrence_policy_name"
                  type="text"
                  disabled={elementDisable()}
                  {...register("insurrence_policy_name", { required: false })}
                  autoComplete="description"
                  className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.insurrence_policy_name ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.insurrence_policy_name ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.insurrence_policy_name ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.insurrence_policy_name && "error-input"}`}
                  placeholder=""
                />
                {errors.insurrence_policy_name && <span className='text-xs text-red-700'>This field is required</span>}
              </div>
              <div className='mb-2'>
                <label htmlFor="insurrence-expiry-date" className='text-sm font-ubuntu'>
                  Insurrence Expiry Date
                </label>
                <input
                  id="insurrence-expiry-date"
                  name="insurrence_expiry_date"
                  type="date"
                  disabled={elementDisable()}
                  {...register("insurrence_expiry_date", { required: false })}
                  autoComplete="description"
                  className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.insurrence_expiry_date ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.insurrence_expiry_date ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.insurrence_expiry_date ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.insurrence_expiry_date && "error-input"}`}
                  placeholder=""
                />
                {errors.insurrence_expiry_date && <span className='text-xs text-red-700'>This field is required</span>}
              </div>
              <div className='mb-2'>
                <label htmlFor="insurrence-name" className='text-sm font-ubuntu'>
                  Insurrence Name
                </label>
                <input
                  id="insurrence-name"
                  name="insurrence_name"
                  type="text"
                  disabled={elementDisable()}
                  {...register("insurrence_name", { required: false })}
                  autoComplete="description"
                  className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.insurrence_name ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.insurrence_name ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.insurrence_name ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.insurrence_name && "error-input"}`}
                  placeholder=""
                />
                {errors.insurrence_name && <span className='text-xs text-red-700'>This field is required</span>}
              </div>
              <div className='mb-2'>
                <label htmlFor="insurrence-owner-name" className='text-sm font-ubuntu'>
                  Vehicle Owner Name
                </label>
                <input
                  id="insurrence-owner-name"
                  name="insurrence_owner_name"
                  type="text"
                  disabled={elementDisable()}
                  {...register("insurrence_owner_name", { required: false })}
                  autoComplete="description"
                  className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.insurrence_owner_name ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.insurrence_owner_name ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.insurrence_owner_name ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.insurrence_owner_name && "error-input"}`}
                  placeholder=""
                />
                {errors.insurrence_owner_name && <span className='text-xs text-red-700'>This field is required</span>}
              </div>
              <div className='mb-2'>
                <label htmlFor="insurrence-driver-name" className='text-sm font-ubuntu'>
                  Vehicle Driver Name
                </label>
                <input
                  id="insurrence-driver-name"
                  name="insurrence_driver_name"
                  type="text"
                  disabled={elementDisable()}
                  {...register("insurrence_driver_name", { required: false })}
                  autoComplete="description"
                  className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.insurrence_driver_name ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.insurrence_driver_name ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.insurrence_driver_name ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.insurrence_driver_name && "error-input"}`}
                  placeholder=""
                />
                {errors.insurrence_driver_name && <span className='text-xs text-red-700'>This field is required</span>}
              </div>
              <div className='mb-2'>
                <label htmlFor="vehicle-category" className='text-sm font-ubuntu'>
                  Vehicle Category
                </label>
                <DropDownSelect
                  register={register}
                  options={vehicleTypeList}
                  defaultValue={selectedVehicle && vehicleTypeList.find(x => x.value == selectedVehicle.vehicle_category)}
                  setSelected={(e) => { setValue("vehicle_category", e.value) }}
                  control={control}
                  name={"vehicle_category"}
                />
                {errors.vehicle_category && <span className='text-xs text-red-700'>This field is required</span>}
              </div>

            </div>
            <ImageUploadComponent
             setImageList={setAttachments} 
             attachments={attachments} 
             disabled={elementDisable()}
             actionType={actionType}
             component={component}
            />
            <div>
              <div className='py-2'>
                <CustomFieldComponent
                  register={register}
                  control={control}
                  customFieldList={customFieldList}
                  setCustomFieldList={setCustomFieldList}
                  addCustomField={addCustomField}
                  editCustomField={editCustomField}
                  deleteCustomField={deleteCustomField}
                  identifireComponentName={"vehicle"}
                  index={vehicleList ? vehicleList.length : 0}
                  actionType={actionType}
                  component={component}
                />
              </div>
            </div>
          </div>
        </div>
        {showButton() && <div className="bg-gray-50 py-3 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-sm bg-white px-3 py-1 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            onClick={() => setProperty()}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-sm bg-theme_color-600 px-3 py-1 text-sm text-white shadow-sm hover:bg-theme_color-700 sm:ml-3 sm:w-auto mr-3"
            onClick={handleSubmit(onSubmit)}
          >
            Confirm
          </button>
        </div>}
      </div>
  </form>
  )
}

export default VehicleModalComponent