import React from 'react'
import {useSelector} from 'react-redux'
import InvoiceStatistics from './invoice_statistics'
import PurchaseAndSalesFilter from './purchase_and_sales.filter'
import PurchaseInvoiceStatistics from './purchase_invoice_statistics'
import SalesInvoiceStatistics from './sales_invoice_statistics'

const component_name_list = [
  "purchase_and_sales_chart",
  "invoice_statistics",
  "sales_invoice_statistics",
  "purchase_invoice_statistics"
]

const filter_components = {
    "purchase_and_sales_chart": PurchaseAndSalesFilter,
    "invoice_statistics": InvoiceStatistics,
    "sales_invoice_statistics": SalesInvoiceStatistics,
    "purchase_invoice_statistics": PurchaseInvoiceStatistics
}

function FilterMainComponent(props) {
    const {register, control} = props

    const filter = useSelector((state) => state.filter.value)

    const UIComponent = filter_components[filter.component]
    if(UIComponent){
        return <UIComponent filterComponentName={filter.component} register={register} control={control}/>
    }
  return (
    <div>
      Not Available.
    </div>
  )
}

export default FilterMainComponent