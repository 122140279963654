import React,{useState} from 'react'
import VehicleModalComponent from '../../../component/ui_component/vehicle.modal'

function VehicleAddOrEditPageComponent() {
  const [actionType, setActionType] = useState('add')

  function ToggleVehicleModal(){

  }
  function vehicleModal(){

  }
  function AddVehicle(){

  }

  return (
    <VehicleModalComponent
      AddVehicle={AddVehicle}
      component={"add_vehicle"}
      actionType={actionType}
    />
  )
}

export default VehicleAddOrEditPageComponent