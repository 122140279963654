import React from 'react'
import DataTable from 'react-data-table-component';

import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ComfirmModalComponent from '../../component/ui_component/confirm.modal';


function TableComponent(props) {
    const {column, docs, expandable = true, pagination = true} = props
    console.log(props, 'props')
  return (
    <DataTable
          columns={column}
          data={docs}
        //   expandableRows={expandable}
        //   expandableRowsComponent={ExpandedComponent}
          pagination={pagination}
          paginationServer
        // paginationServerOptions={}
        // paginationTotalRows={totalDocs}
        // onChangePage={(p) => OnChange(p)}
        // onChangeRowsPerPage={OnChange}
        />
  )
}

export default TableComponent