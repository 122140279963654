import React, { useEffect, useState } from "react";
import theme from '../constants/app.theme'

import {
  useNavigate
} from 'react-router-dom'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import StoreIcon from '@mui/icons-material/Store';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

import { ReactComponent as HomeSVG } from '../resouces/svg/home.svg'
import { ReactComponent as CustomerSVG } from '../resouces/svg/customer.svg'
import { ReactComponent as VehicleSVG } from '../resouces/svg/vehicle.svg'
import { ReactComponent as UserSVG } from '../resouces/svg/user.svg'
import { ReactComponent as InventorySVG } from '../resouces/svg/inventory.svg'
import { ReactComponent as BillingSVG } from '../resouces/svg/billing.svg'
import { ReactComponent as CompanyDetailsSVG } from '../resouces/svg/company_details.svg'
import { ReactComponent as LeftArrowSVG } from '../resouces/svg/left_arrow.svg'
import { ReactComponent as RightArrowSVG } from '../resouces/svg/right_arrow.svg'

import { AiOutlineLeft } from 'react-icons/ai';
import { AiOutlineRight } from 'react-icons/ai';

import { ThemeColor } from "../utilities/theme.color";


function LeftLayout(props) {
  const {sideBarType, toggle_sidebar} = props

  const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState('dashboard')

    const route = [
        {
            name: 'dashboard',
            route: '/',
            is_home: true,
            lable: 'Dashboard',
            icon: HomeSVG
        },
        {
            name: 'customer',
            route: '/customer',
            lable: 'Customer',
            icon: CustomerSVG
        },
        {
            name: 'vendor',
            route: '/vendor',
            lable: 'Vendor',
            icon: ContactPageOutlinedIcon
        },
        {
            name: 'inventory',
            route: '/inventory',
            lable: 'Inventory',
            icon: InventorySVG
        },
        {
            name: 'purchase_order',
            route: '/purchase-order',
            lable: 'Purchase Order',
            icon: ShoppingCartOutlinedIcon
        },
        {
            name: 'invoice',
            route: '/invoice',
            lable: 'Invoice',
            icon: BillingSVG
        },
        {
            name: 'company_details',
            route: '/company-details',
            lable: 'Company Details',
            icon: CompanyDetailsSVG
        },
    ]

    const path = {
        "/": "dashboard",
        "/customer": "customer",
        "/vendor": "vendor",
        "/inventory": "inventory",
        "/purchase_order": "purchase_order",
        "/invoice": "invoice",
        "/company-details": "company_details"
    }

    const location = window.location.toString()
    const location_split = location.split('/')
    const path_name = window.location.pathname

    useEffect(() => {
        setActiveTab(path[path_name])
    }, [])

    function naviagateToRoute(route){
        navigate(route.route)
        setActiveTab(route.name)
    }

    function generateClassName({name}){
        if (name == activeTab ){
            return 'active-tab'
        }
        return 'side-bar-tab'
    }

  const sidebar_with = sideBarType == "min" ? theme.sidebar_min_with : theme.sidebar_max_with
  return (
    <div className={`side-bar w-full h-full p-2 relative shadow-lg bg-theme_color-700`}>
        <div className="side_bar_visible_button bg-theme_color-700 cursor-pointer" onClick={() => toggle_sidebar()}>
            {sideBarType == "min" ? <RightArrowSVG color="white" size={14} className="cursor-pointer" style={{zIndex: 100}} onClick={() => toggle_sidebar()}/>
            :<LeftArrowSVG color="white" size={14} className="cursor-pointer" style={{zIndex: 100}} onClick={() => toggle_sidebar()}/>
            }
        </div>
        <div className="p-2">
            <img
                className="h-8"
                src={`https://tailwindui.com/img/logos/mark.svg?color=grey&shade=600`}
                alt="Your Company"
            />
        </div>
      {route.map((r, index) => (
          <div key={index} className={`p-2 cursor-pointer ${generateClassName(r)}`} onClick={() => naviagateToRoute(r)}>
              {sideBarType == 'min' ?
                  (
                      <Tooltip title={r.lable}>
                          <IconButton>
                              <r.icon style={{color: r.name == activeTab ? "white" : "white"}}/>
                          </IconButton>
                      </Tooltip>
                  )
                  : (<>
                    <div className="w-full flex items-center">
                        <r.icon style={{color: "rgba(255,255,255,1)"}}/>
                        <div className="text-sm ml-2 font-ubuntu" style={{color: "white", whiteSpace: "nowrap"}}>{r.lable}</div>
                    </div>
                </>)
              }
          </div>
      ))}
    </div>
  )
}

export default LeftLayout