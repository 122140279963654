import React, { useState } from 'react'
import AddCustomerPage from './addoredit.customer.page'
import CustomerPage from './customer.page'
import CustomerDetailsPage from './details.customer.page'

function CustomerPageHandler() {
    const [actionType, setActionType] = useState('listing')
    switch(actionType){
        case "listing":
            return (
                <CustomerPage setActionType={setActionType}/>
            )
        case 'add':
            return (
                <AddCustomerPage setActionType={setActionType}/>
            )
        case 'details':
            return (
                <CustomerDetailsPage setActionType={setActionType}/>
            )
        case 'edit':
            return (
                <CustomerDetailsPage setActionType={setActionType} isEdit={true}/>
            )
        default:
            return <></>
    }
}

export default CustomerPageHandler