import React, { useState } from 'react'
import { ReactComponent as FilterSVG } from '../../resouces/svg/filter.svg'
import {useNavigate} from 'react-router-dom'

import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ComfirmModalComponent from '../../component/ui_component/confirm.modal';
import TableComponent from '../../component/ui_component/table';


function InvoiceList(props) {
    const {setActionType} = props

    const navigation = useNavigate()
    const [deleteinvoice, setDeleteinvoice] = useState({modal: false, selected_details: {}})
  
    const columns = [
      {
        name: 'S.NO',
        selector: (row, index) => index + 1,
      },
      {
        name: 'INVOICE NO',
        selector: row => row.invoice_no,
      },
      {
        name: 'CUSTOMER',
        selector: row => row.invoice_customer_name,
      },
      {
        name: 'STATUS',
        selector: row => row.invoice_status,
      },
      {
        name: 'PAYMENT STATUS',
        selector: row => row.payment_status,
      },
      {
        name: 'ACTION',
        selector: row => (
          <>
            <IconButton onClick={() => {
              NavigationHandler(`/invoice/${'937392'}/details`)
            }}>
              <VisibilityIcon style={{ color: 'rgba(0,0,0,0.3)', size: '7px' }} />
            </IconButton>
            <IconButton onClick={() => {
              toggle_delete_invoice()  
            }}>
              <DeleteOutlineIcon style={{ color: 'rgba(255,0,0,0.5)', size: '5px' }} />
            </IconButton>
          </>
        )
      }
    ];
  
    const toggle_delete_invoice = () => {
      setDeleteinvoice({modal: !deleteinvoice.modal, ...setDeleteinvoice.selected_details})
    }
  
    const delete_invoice = (payload) => {
      console.log(payload, 'payload')
    }
  
    const NavigationHandler = (url) => {
      navigation(url)
    }
  
    const docs = [
      {
        invoice_no: '001',
        invoice_customer_name: "kathiravan",
        invoice_status: "pending",
        payment_status: "Paid"
      },
      {
        invoice_no: '001',
        invoice_customer_name: "kathiravan",
        invoice_status: "pending",
        payment_status: "Paid"
      },
      {
        invoice_no: '001',
        invoice_customer_name: "kathiravan",
        invoice_status: "pending",
        payment_status: "Paid"
      },
      {
        invoice_no: '001',
        invoice_customer_name: "kathiravan",
        invoice_status: "pending",
        payment_status: "Paid"
      },
      {
        invoice_no: '001',
        invoice_customer_name: "kathiravan",
        invoice_status: "pending",
        payment_status: "Paid"
      },
      {
        invoice_no: '001',
        invoice_customer_name: "kathiravan",
        invoice_status: "pending",
        payment_status: "Paid"
      },
      {
        invoice_no: '001',
        invoice_customer_name: "kathiravan",
        invoice_status: "pending",
        payment_status: "Paid"
      },
      {
        invoice_no: '001',
        invoice_customer_name: "kathiravan",
        invoice_status: "pending",
        payment_status: "Paid"
      },
      {
        invoice_no: '001',
        invoice_customer_name: "kathiravan",
        invoice_status: "pending",
        payment_status: "Paid"
      },
      {
        invoice_no: '001',
        invoice_customer_name: "kathiravan",
        invoice_status: "pending",
        payment_status: "Paid"
      }
    ]
    return (
      <div className='flex flex-col h-full p-5 pt-0'>
        <div className='flex items-center justify-between h-10 mb-2'>
          <div className='font-roboto font-bold'>Invoice List</div>
          <div>
            <button class="font-roboto bg-theme_color-600 hover:bg-theme_color-700 text-white text-sm py-1 px-4 border border-theme_color-700 rounded mr-2" type="button" onClick={() => NavigationHandler(`/invoice/add`)}>
              Add New
            </button>
            <IconButton type="button" deleteinvoice >
              <FilterSVG style={{ color: "rgba(0,0,0,0.3)" }} />
            </IconButton>
          </div>
        </div>
        <div className='flex-grow pb-4'>
          <TableComponent
            column={columns}
            docs={docs}
          />
          <ComfirmModalComponent setProperty={toggle_delete_invoice} modal={deleteinvoice.modal}/>
        </div>
      </div>
    )
}

export default InvoiceList