import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { useDispatch } from 'react-redux'
import { toggleFilter, updateFilter } from '../../redux/filter/index'

//   import faker from 'faker';

import { ReactComponent as FilterSVG } from '../../resouces/svg/filter.svg'
import { IconButton } from '@mui/material';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function SalesChart(props) {
  const { filter } = props

  const dispatch = useDispatch()

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        // display: true,
        // text: 'Chart.js Bar Chart',
      },
    },
  };

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  const data = {
    labels,
    datasets: [
      {
        label: 'Purchase',
        data: [20, 49, 6, 5, 44, 58, 58],
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Sales',
        data: [10, 30, 90, 8, 43, 58, 57],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  // {component: "purchase_and_sales_chart", screen: "dashboard"}
  return (
    <div className='p-2'>
      <div className='flex items-center justify-between'>
        <div className='text-center flex-grow text-sm text-base font-normal font-roboto'>MONTHLY REPORTS</div>
        <IconButton onClick={() => dispatch(toggleFilter({ component: "purchase_and_sales_chart" }))}>
          <div className='filter-nofify'></div>
          <FilterSVG style={{ color: "rgba(0,0,0,0.3)" }} />
        </IconButton>
      </div>
      <Bar options={options} data={data} />
    </div>
  )
}

export default SalesChart