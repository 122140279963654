import React from 'react'
import PurchaseOrderAction from './purchase_order.action'

function PurchaseOrderAdd() {
  return (
    <PurchaseOrderAction
      actionType={"add"}
    />
  )
}

export default PurchaseOrderAdd