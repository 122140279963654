import React, { useState } from 'react'
import VendorAdd from './vendor.add'

function VendorDetails() {
  const [actionType, setActionType] = useState('view')

  function toggleActionType(){
    if(actionType == "view"){
      setActionType("edit")
    }
    else if(actionType == "edit"){
      setActionType("view")
    }
  }

  function editVendor(values){
    console.log(values, 'vendor parent componet')
  }

  return (
    <VendorAdd
      editVendor={editVendor}
      actionType={actionType}
      toggleActionType={toggleActionType}
    />
  )
}

export default VendorDetails