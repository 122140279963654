import { IconButton } from '@mui/material'
import React, { useLayoutEffect, useState, Fragment, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import CustomFieldComponent from '../../component/ui_component/customfield'
import DropDownSelect from '../../component/ui_component/dropdown_select'
import VehicleModalComponent from '../../component/ui_component/vehicle.modal'
import { Dialog, Transition } from '@headlessui/react'

import { ReactComponent as PlusIcon } from '../../resouces/svg/plus.svg'
import VehicleCard from './vehicle_card.'

function CustomerAddOrEditPage(props) {
  const navigate = useNavigate()
  const { register, control, handleSubmit, formState: { errors } } = useForm()
  // const [open, setOpen] = useState(modal)
  const [categoryList, setCategoryList] = useState([{ label: "General", value: "general" }, { label: "VIB", value: 'vib' }])
  const [customFieldList, setCustomFieldList] = useState([])
  let [isOpen, setIsOpen] = useState(false)


  const [vehicleModal, setVehicleModal] = useState(false)
  const [selectedVehicle, setSelectedVehicle] = useState(null)
  const [vehicleList, setVehicleList] = useState([
    // {
    //   registration_number: "0001",
    //   vehicle_name: "Honda",
    //   vehicle_model: 2023,
    //   registration_number: 783447937
    // }
  ])
  const [actionType, setActionType] = useState('add')
  const cancelButtonRef = useRef(null)


  const url = window.location.href

  console.log(url, 'url')

  useLayoutEffect(() => {
    const split = url.split('/')
    if (split.includes('edit')) {
      setActionType('edit')
    }
  }, [url])


  console.log(customFieldList, 'customFieldList')

  const ToggleVehicleModal = () => {
    setVehicleModal(!vehicleModal)
  }

  const AddVehicle = (values) => {
    const clone = [...vehicleList]
    clone.push(values)
    setVehicleList(clone)
    ToggleVehicleModal()
    console.log(values)

  }

  const EditVehicle = (values) => {
    console.log(values)
    const clone = [...vehicleList]
    const find_index = vehicleList.findIndex(x => x.registration_number == values.registration_number)
    if (find_index > -1) {
      clone[find_index] = values
      setVehicleList(clone)
    }
    ToggleVehicleModal()
  }

  const onSubmit = (values) => {
    console.log(values, 'values 1')
    console.log(vehicleList, 'vehiclelist')
    console.log(customFieldList, 'custom_field list')

    const payload = {
      billing_address: {
        street: values.billing_address_street,
        city: values.billing_address_city,
        state: values.billing_address_state,
        country: values.billing_address_country,
        zip_code: values.billing_address_zipcode
      },
      service_address: {
        street: values.service_address_street,
        city: values.service_address_city,
        state: values.service_address_state,
        country: values.service_address_country,
        zip_code: values.service_address_zipcode
      },
      customer_first_name: values.customer_first_name,
      customer_last_name: values.customer_last_name,
      customer_email: values.customer_email,
      customer_phone_number: values.customer_phone_number,
      custom_category: values.customer_category
    }
  }

  const deleteVehicle = (index) => {
    const clone = [...vehicleList]
    clone.splice(index, 1)
    setVehicleList(clone)
  }

  const addCustomField = (values) => {
    const payload = {
      name: values.field_name,
      type: values.field_type,
      placeholder: values.placeholder,
      description: values.description,
      options: values.options ? values.options.split('\n') : [],
      hidden: values.hidden_field,
      visible_only_admin: values.visible_only_admin,
      index: customFieldList.length + 1
    }
    setCustomFieldList([...customFieldList, payload])
  }

  const editCustomField = (values, prev_values) => {
    console.log({ values, prev_values })
    const findIndex = customFieldList.findIndex(x => x.index == prev_values.index)
    const clone = customFieldList
    const payload = {
      name: values.field_name,
      type: values.field_type.value ? values.field_type.value : values.field_type,
      placeholder: values.placeholder,
      description: values.description,
      options: values.options ? values.options.split('\n') : [],
      hidden: values.hidden_field,
      visible_only_admin: values.visible_only_admin,
      index: prev_values.index
    }
    if (findIndex > -1) {
      clone[findIndex] = payload
    } else {
      clone.push({ ...payload, index: customFieldList.length + 1 })
    }
    console.log(clone, 'clone')
    setCustomFieldList([...clone])
  }

  const deleteCustomField = (field) => {
    const clone = [...customFieldList]
    clone.splice(field.index, 1)
    setCustomFieldList(clone)
    deleteCustomField(field)
  }

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <div className='flex flex-col h-full'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='sticky'>
          <div className='flex items-center justify-between'>
            <div className='h-14 font-semibold font-roboto'><span className='cursor-pointer' onClick={() => navigate("/customer")}>Customer</span> {actionType == "add" ? <span>/ New</span> : <span className='cursor-pointer' onClick={() => navigate("/customer/97849/details")}>/ Kathiravan</span>} {actionType == "edit" && "/ Edit"} </div>
            <button
              type="submit"
              className="font-roboto inline-flex w-full justify-center rounded-sm bg-theme_color-600 px-3 py-1 text-sm text-white shadow-sm hover:bg-theme_color-700 sm:ml-3 sm:w-auto mr-3"
            // onClick={() => setProperty()}
            >
              {actionType == "add" ? "Create" : actionType == "edit" ? "Update" : ""}
            </button>
          </div>
        </div>
        <div className='flex flex-col h-full'>
          <div className='p-2 flex items-start justify-between'>
            <div className='box-card mr-2 p-4' style={{ width: '70%' }}>
              <div className='grid grid-cols-2 gap-3'>
                <div className='mb-2'>
                  <label htmlFor="customer-name" className='text-sm font-ubuntu'>
                    Customer Name
                  </label>
                  <input
                    id="customer-name"
                    name="customer_name"
                    type="text"
                    {...register("customer_name", { required: true })}
                    autoComplete="customer_name"
                    className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.customer_name ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.customer_name ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.customer_name ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.customer_name && "error-input"}`}
                    placeholder=""
                  />
                  {errors.customer_name && <span className='text-xs text-red-700'>This field is required</span>}
                </div>
                <div className='mb-2'>
                  <label htmlFor="customer-email" className='text-sm'>
                    Customer Email
                  </label>
                  <input
                    id="customer-email"
                    name="customer_email"
                    type="text"
                    {...register("customer_email", { required: true })}
                    autoComplete="customer_email"
                    className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.customer_email ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.customer_email ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.customer_email ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.customer_email && "error-input"}`}
                    placeholder=""
                  />
                  {errors.customer_email && <span className='text-xs text-red-700'>This field is required</span>}
                </div>
                <div className='mb-2'>
                  <label htmlFor="customer-phone-number" className='text-sm font-ubuntu'>
                    Customer Phone Number
                  </label>
                  <input
                    id="customer-phone-number"
                    name="customer_phone_number"
                    type="text"
                    {...register("customer_phone_number", { required: true })}
                    autoComplete="customer_phone_number"
                    className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.customer_phone_number ? "border-red-700" : `border-gray-500`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.customer_phone_number ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.customer_phone_number ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.customer_phone_number && "error-input"}`}
                    placeholder=""
                  />
                  {errors.customer_phone_number && <span className='text-xs text-red-700'>This field is required</span>}
                </div>
                <div className='mb-2'>
                  <label htmlFor="customer-category" className='text-sm font-ubuntu'>
                    Customer Category
                  </label>
                  <DropDownSelect
                    register={register}
                    options={categoryList}
                    defaultValue={categoryList[0]}
                    setSelected={(e) => { console.log(e, 'e') }}
                    control={control}
                    name={"customer_category"}
                  />
                  {errors.customer_category && <span className='text-xs text-red-700'>This field is required</span>}
                </div>
              </div>
              <div className='grid grid-cols-2 gap-3 mt-4'>
                <div>
                  <div className='font-normal font-roboto'>Service Address</div>
                  <div className='mt-2'>
                    <div className='mb-2'>
                      <label htmlFor="service-address-street" className='text-sm font-ubuntu'>
                        Street
                      </label>
                      <input
                        id="service-address-street"
                        name="service_address_street"
                        type="text"
                        {...register("service_address_street", { required: true })}
                        autoComplete="service_address_street"
                        className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.service_address_street ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.service_address_street ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.service_address_street ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.service_address_street && "error-input"}`}
                        placeholder=""
                      />
                      {errors.service_address_street && <span className='text-xs text-red-700'>This field is required</span>}
                    </div>
                    <div className='mb-2'>
                      <label htmlFor="service-address-city" className='text-sm font-ubuntu'>
                        City
                      </label>
                      <input
                        id="service-address-city"
                        name="service_address_city"
                        type="text"
                        {...register("service_address_city", { required: true })}
                        autoComplete="service_address_city"
                        className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.service_address_city ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.service_address_city ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.service_address_city ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.service_address_city && "error-input"}`}
                        placeholder=""
                      />
                      {errors.service_address_city && <span className='text-xs text-red-700'>This field is required</span>}
                    </div>
                    <div className='mb-2'>
                      <label htmlFor="service-address-state" className='text-sm font-ubuntu'>
                        State
                      </label>
                      <input
                        id="service-address-state"
                        name="service_address_state"
                        type="text"
                        {...register("service_address_state", { required: true })}
                        autoComplete="service_address_state"
                        className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.service_address_state ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.service_address_state ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.service_address_state ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.service_address_state && "error-input"}`}
                        placeholder=""
                      />
                      {errors.service_address_state && <span className='text-xs text-red-700'>This field is required</span>}
                    </div>
                    <div className='mb-2'>
                      <label htmlFor="service-address-country" className='text-sm font-ubuntu'>
                        Country
                      </label>
                      <input
                        id="service-address-country"
                        name="service_address_country"
                        type="text"
                        {...register("service_address_country", { required: true })}
                        autoComplete="service_address_country"
                        className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.service_address_country ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.service_address_country ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.service_address_country ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.service_address_country && "error-input"}`}
                        placeholder=""
                      />
                      {errors.service_address_country && <span className='text-xs text-red-700'>This field is required</span>}
                    </div>
                    <div className='mb-2'>
                      <label htmlFor="service-address-zipcode" className='text-sm font-ubuntu'>
                        ZIP Code
                      </label>
                      <input
                        id="service-address-zipcode"
                        name="service_address_zipcode"
                        type="text"
                        {...register("service_address_zipcode", { required: true })}
                        autoComplete="service_address_zipcode"
                        className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.service_address_zipcode ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.service_address_zipcode ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.service_address_zipcode ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.service_address_zipcode && "error-input"}`}
                        placeholder=""
                      />
                      {errors.service_address_zipcode && <span className='text-xs text-red-700'>This field is required</span>}
                    </div>
                  </div>
                </div>
                <div>
                  <div className='font-normal font-roboto'>Billing Address</div>
                  <div className='mt-2'>
                    <div className='mb-2'>
                      <label htmlFor="billing-address-street" className='text-sm font-ubuntu'>
                        Street
                      </label>
                      <input
                        id="billing-address-street"
                        name="billing_address_street"
                        type="text"
                        {...register("billing_address_street", { required: true })}
                        autoComplete="billing_address_street"
                        className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.billing_address_street ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.billing_address_street ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.billing_address_street ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.billing_address_street && "error-input"}`}
                        placeholder=""
                      />
                      {errors.billing_address_street && <span className='text-xs text-red-700'>This field is required</span>}
                    </div>
                    <div className='mb-2'>
                      <label htmlFor="billing-address-city" className='text-sm font-ubuntu'>
                        City
                      </label>
                      <input
                        id="billing-address-city"
                        name="billing_address_city"
                        type="text"
                        {...register("billing_address_city", { required: true })}
                        autoComplete="billing_address_city"
                        className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.billing_address_city ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.billing_address_city ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.billing_address_city ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.billing_address_city && "error-input"}`}
                        placeholder=""
                      />
                      {errors.billing_address_city && <span className='text-xs text-red-700'>This field is required</span>}
                    </div>
                    <div className='mb-2'>
                      <label htmlFor="billing-address-state" className='text-sm font-ubuntu'>
                        State
                      </label>
                      <input
                        id="billing-address-state"
                        name="billing_address_state"
                        type="text"
                        {...register("billing_address_state", { required: true })}
                        autoComplete="billing_address_state"
                        className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.billing_address_state ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.billing_address_state ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.billing_address_state ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.billing_address_state && "error-input"}`}
                        placeholder=""
                      />
                      {errors.billing_address_state && <span className='text-xs text-red-700'>This field is required</span>}
                    </div>
                    <div className='mb-2'>
                      <label htmlFor="billing-address-country" className='text-sm font-ubuntu'>
                        Country
                      </label>
                      <input
                        id="billing-address-country"
                        name="billing_address_country"
                        type="text"
                        {...register("billing_address_country", { required: true })}
                        autoComplete="billing_address_country"
                        className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.billing_address_country ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.billing_address_country ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.billing_address_country ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.billing_address_country && "error-input"}`}
                        placeholder=""
                      />
                      {errors.billing_address_country && <span className='text-xs text-red-700'>This field is required</span>}
                    </div>
                    <div className='mb-2'>
                      <label htmlFor="billing-address-zipcode" className='text-sm font-ubuntu'>
                        ZIP Code
                      </label>
                      <input
                        id="billing-address-zipcode"
                        name="billing_address_zipcode"
                        type="text"
                        {...register("billing_address_zipcode", { required: true })}
                        autoComplete="billing_address_zipcode"
                        className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.billing_address_zipcode ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.billing_address_zipcode ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.billing_address_zipcode ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.billing_address_zipcode && "error-input"}`}
                        placeholder=""
                      />
                      {errors.billing_address_zipcode && <span className='text-xs text-red-700'>This field is required</span>}
                    </div>
                  </div>
                </div>
              </div>
              <div className='mt-3'>
                <CustomFieldComponent
                  register={register}
                  control={control}
                  customFieldList={customFieldList}
                  setCustomFieldList={setCustomFieldList}
                  addCustomField={addCustomField}
                  editCustomField={editCustomField}
                  deleteCustomField={deleteCustomField}
                  identifireComponentName={"customer"}
                  index={0}
                />
              </div>
            </div>
            <div style={{ width: '30%' }}>
              <div className='p-4 box-card mb-3'>
                <div className='flex items-center justify-between'>
                  <div className='font-normal font-roboto'>Customer Vehicles</div>
                  <span className='cursor-pointer'>
                    <IconButton style={{ backgroundColor: 'rgba(0,0,0,0.1)' }} onClick={() => {
                      ToggleVehicleModal()
                      setSelectedVehicle(null)
                    }}>
                      <PlusIcon style={{ height: 20, width: 20 }} />
                    </IconButton>
                  </span>
                </div>
                <div className='h-full'>
                  {
                    vehicleList.map((vehicle, index) => {
                      return (
                        <VehicleCard
                          vehicle={vehicle}
                          index={index}
                          deleteVehicle={deleteVehicle}
                          ToggleVehicleModal={ToggleVehicleModal}
                          selectedVehicle={selectedVehicle}
                          setSelectedVehicle={setSelectedVehicle}
                        />
                      )
                    })
                  }
                </div>
              </div>
              <div className='p-4 box-card'>
                <div className='font-roboto'>Associations <span className='text-sm text-gray-400'>(Comming Soon)</span></div>
              </div>
            </div>
          </div>
        </div>
       <Transition 
          appear
          show={vehicleModal} 
          as={Fragment}>
          <Dialog as="div" 
            className="relative z-10"
            onClose={() => setVehicleModal(false)}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-base font-normal leading-6 text-gray-900 font-roboto">
                    VEHICLE INFORMATION
                  </Dialog.Title>
                  <VehicleModalComponent
                    setProperty={ToggleVehicleModal}
                    modal={vehicleModal}
                    AddVehicle={AddVehicle}
                    selectedVehicle={selectedVehicle}
                    EditVehicle={EditVehicle}
                    vehicleList={vehicleList}
                    cancelButtonRef={cancelButtonRef}
                    component='customer_vehicle'
                  />
                </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </form>
    </div>
  )
}

export default CustomerAddOrEditPage