import {createSlice} from '@reduxjs/toolkit'

const filterSlice = createSlice({
    name: 'filter',
    initialState: {value: {open: false, component: "", filter: {}}},
    reducers: {
        toggleFilter: (state, action) => {
            let payload = {
                ...state.value,
                open: !state.value.open,
            }

            if(payload.open){
                payload = {
                    ...payload,
                    component: action.payload.component
                }
            }
            else{
                payload = {
                    ...payload,
                    // component: ""
                }
            }
            
            state.value = payload
        },
        updateFilter: (state, action) => {
            const {payload = {}} = action.payload
            state.value = {
                ...state.value,
                filter: {
                    ...state.value.filter,
                    [state.value.component]: payload
                }
            }
        },
        clearFilter: (state, action) => {
            const filter = state.value.filter
            if(filter[state.value.component]){
                delete filter[`${state.value.component}`]
            }
            state.value = {
                ...state.value,
                filter
            }
        }
    }
})

export const {toggleFilter, updateFilter, clearFilter} = filterSlice.actions

export default filterSlice.reducer