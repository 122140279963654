import React,{useState, useEffect} from 'react'
import RouteLayout from "./route/index.route";
import {Provider} from 'react-redux'
import {store} from './redux/store'


function App() {
  const [theme, setTheme] = useState('light');
    const colorTheme = theme === "dark" ? "light" : "dark";
  
    useEffect(() => {
        const root = window.document.documentElement;
        root.classList.remove(colorTheme);
        root.classList.add(theme);
        localStorage.setItem('theme', theme);
    }, [theme, colorTheme]);
  
  return (
    <>
    {/*<button onClick={() => setTheme(theme == 'light' ? 'dark' : 'light')}>Toggle</button>*/}
    <Provider store={store}>
      <RouteLayout/>
    </Provider>
    </>
  );
}

export default App;
