import React, { useState } from 'react'
import { ReactComponent as FilterSVG } from '../../../resouces/svg/filter.svg'
import DataTable from 'react-data-table-component';
import {useNavigate} from 'react-router-dom'

import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ComfirmModalComponent from '../../../component/ui_component/confirm.modal';
import TableComponent from '../../../component/ui_component/table';

function VehicleListComponent() {
    const navigation = useNavigate()
  const [deleteVehicle, setDeleteVehicle] = useState({modal: false, selected_details: {}})

  const columns = [
    {
      name: 'S.NO',
      selector: (row, index) => index + 1,
    },
    {
      name: 'REGISTRATION NO',
      selector: row => row.registration_no,
    },
    {
      name: 'ENGINE NUMBER',
      selector: row => row.engine_no,
    },
    {
      name: 'MODEL',
      selector: row => row.vehicle_model,
    },
    {
      name: 'NAME',
      selector: row => row.vehicle_name,
    },
    {
      name: 'ACTION',
      selector: row => (
        <>
          <IconButton onClick={() => {
            NavigationHandler(`/customer/${'937392'}/vehicle/754949/details`)
          }}>
            <VisibilityIcon style={{ color: 'rgba(0,0,0,0.3)', size: '7px' }} />
          </IconButton>
          <IconButton onClick={() => {
            toggle_delete_vehicle()  
          }}>
            <DeleteOutlineIcon style={{ color: 'rgba(255,0,0,0.5)', size: '5px' }} />
          </IconButton>
        </>
      )
    }
  ];

  const toggle_delete_vehicle = () => {
    setDeleteVehicle({modal: !deleteVehicle.modal, ...setDeleteVehicle.selected_details})
  }

  const NavigationHandler = (url) => {
    navigation(url)
  }

  const docs = [
    {
        registration_no: '983484',
        engine_no: "93844",
        vehicle_model: "2023",
        vehicle_name: "BMW"
    },
    {
        registration_no: '88494',
        engine_no: "5584",
        vehicle_model: "2020",
        vehicle_name: "FZ"
    },
  ]

  return (
    <div className='flex flex-col h-full p-5 pt-0'>
      <div className='flex items-center justify-between h-10 mb-2'>
        <div className='font-roboto font-bold'>Kathiravan / Vehicle List</div>
        <div>
          <button class="font-roboto bg-theme_color-600 hover:bg-theme_color-700 text-white text-sm py-1 px-4 border border-theme_color-700 rounded mr-2" type="button" onClick={() => NavigationHandler(`/customer/744944/vehicle/add`)}>
            Add New
          </button>
          <IconButton type="button" deleteVehicle >
            <FilterSVG style={{ color: "rgba(0,0,0,0.3)" }} />
          </IconButton>
        </div>
      </div>
      <div className='flex-grow pb-4'>
        <TableComponent
          column={columns}
          docs={docs}
        />
        <ComfirmModalComponent 
            setProperty={toggle_delete_vehicle} 
            modal={deleteVehicle.modal}
        />
      </div>
    </div>
  )
}

export default VehicleListComponent