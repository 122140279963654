import {configureStore} from '@reduxjs/toolkit'
import DashBoardReducer from './dashboard/index'
import FilterReducer from './filter/index'

const store = configureStore({
    reducer: {
        dashboard: DashBoardReducer,
        filter: FilterReducer
    }
})

export { store }
