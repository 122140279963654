import { IconButton } from '@mui/material'
import React, { useLayoutEffect, useState, Fragment, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Dialog, Transition } from '@headlessui/react'
import Tooltip from '@mui/material/Tooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';


import CustomFieldComponent from '../../component/ui_component/customfield'
import DropDownSelect from '../../component/ui_component/dropdown_select'
import TableComponent from '../../component/ui_component/table';
import { ReactComponent as PlusIcon } from '../../resouces/svg/plus.svg'




function PurchaseOrderAction(props) {
    const navigate = useNavigate()
  const { register, control, handleSubmit, formState: { errors } } = useForm()
  const [categoryList, setCategoryList] = useState([{ label: "General", value: "general" }, { label: "VIB", value: 'vib' }])
  const [customFieldList, setCustomFieldList] = useState([])
  const [selectedpurchase_order, setSelectedpurchase_order] = useState(null)
  const [actionType, setActionType] = useState('add')
  const [products, setProducts] = useState([])
  const [productsModal, setProductsModal] = useState(false)

  const url = window.location.href
  useLayoutEffect(() => {
    if(props.actionType){
      setActionType(props.actionType)
    }
  }, [props.actionType])


  const onSubmit = (values) => {
    console.log(values, 'values 1')
    console.log(customFieldList, 'custom_field list')

    const payload = {
      purchase_order_address: {
        street: values.purchase_order_address_street,
        city: values.purchase_order_address_city,
        state: values.purchase_order_address_state,
        country: values.purchase_order_address_country,
        zip_code: values.purchase_order_address_zipcode
      },
      purchase_order_first_name: values.purchase_order_first_name,
      purchase_order_last_name: values.purchase_order_last_name,
      purchase_order_email: values.purchase_order_email,
      purchase_order_phone_number: values.purchase_order_phone_number,
      purchase_order_category: values.purchase_order_category
    }

    if(actionType == "add"){
      addpurchase_order(values)
    }
    else if(actionType == "edit"){
      props.editpurchase_order(values)
    }
  }

  function addpurchase_order(values){
    console.log(values)
    console.log('add purchase_order')
  }

  function disableElement(){
    if(actionType == "view"){
      return true
    }
    return false
  }

  function actionHandler(){
    if(props.actionType == "view"){
      if(actionType == "view") setActionType('edit')
      else setActionType('view')
    }
  }

  function toggleProductsModal(){
    setProductsModal(!productsModal)
  }

  const columns = [
    {
      name: 'S.NO',
      selector: (row, index) => index + 1,
    },
    {
      name: 'PRODUCT NAME',
      selector: row => row.product_name,
    },
    {
      name: 'PRICE',
      selector: row => row.product_price,
    },
    {
      name: 'QUANTITY',
      selector: row => row.product_quantity,
    },
    {
      name: 'TOTAL',
      selector: row => row.product_total,
    }
  ];

  const docs = [
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    },
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    },
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    },
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    },
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    },
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    },
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    },
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    },
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    },
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    }
  ]

  const columns1 = [
    {
      name: 'S.NO',
      selector: (row, index) => index + 1,
    },
    {
      name: 'PRODUCT NAME',
      selector: row => row.product_name,
    },
    {
      name: 'PRICE',
      selector: row => row.product_price,
    },
    {
      name: 'QUANTITY',
      selector: row => {
        return (
            <input className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.order_payment_due_date ? "border-red-700" : `border-gray-500`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.order_payment_due_date ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.order_payment_due_date ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.order_payment_due_date && "error-input"}`} value={row.product_quantity}/>
        )
      },
    },
    {
      name: 'TOTAL',
      selector: row => row.product_total,
    },
    {
        name: 'Action',
        selector: (row, index) => {
            if(checkIsPartSelected(index, row._id)){
                return (
                    <>  
                        <Tooltip title="Remove">
                            <IconButton onClick={() => {
                                console.log('remove')
                                // onChangePart(row._id, row, 0, 'remove')
                                // warning(`${row.part_name} is removed, ${quantity[row._id]} quantity.`)
                            }}>
                                <RemoveCircleOutlineIcon style={{color: 'rgba(255,0,0, 0.7)'}}/>
                            </IconButton>
                        </Tooltip>
                    </>
                )
            }

            return(
            <>  
                <Tooltip title="Add">
                    <IconButton onClick={() => {
                        console.log('add')
                        // if(quantity[row._id]){
                        //     onChangePart(row._id, row, quantity[row._id], 'add')
                        //     success(`${row.part_name} is added, ${quantity[row._id]} quantity.`)
                        // }else{
                        //     error('please enter part quantity')
                        // }
                    }}>
                        <AddCircleOutlineIcon style={{color: 'rgba(0,255,0, 0.7)'}}/>
                    </IconButton>
                </Tooltip>
            </>
        )
    }
    }
  ];

  const docs1 = [
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    },
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    },
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    },
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    },
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    },
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    },
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    },
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    },
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    },
    {
      product_name: 'break',
      product_price: "140",
      product_quantity: "3",
      product_total: "420"
    }
  ]

  function checkIsPartSelected(index){
    if(index == 0) return false
    return true
  }

  return (
    <div className='flex flex-col h-full'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='sticky'>
          <div className='flex items-center justify-between'>
            <div className='h-14 font-semibold font-roboto'><span className='cursor-pointer' onClick={() => navigate("/purchase_order")}>Purchase Order</span> {actionType == "add" ? <span>/ New</span> : <span className='cursor-pointer' onClick={() => navigate("/purchase_order/97849/details")}>/ Kathiravan</span>} {actionType == "edit" && "/ Edit"} </div>
            {actionType != 'edit' && <button
              type="submit"
              className="font-roboto inline-flex w-full justidfy-center rounded-sm bg-theme_color-600 px-3 py-1 text-sm text-white shadow-sm hover:bg-theme_color-700 sm:ml-3 sm:w-auto mr-3"
            onClick={() => actionHandler()}
            >
              {actionType == "add" ? "Create" : actionType == "edit" ? "Update" : "Edit"}
            </button>}
          </div>
        </div>
        <div className='flex flex-col h-full'>
          <div className='p-2 flex items-start justify-between'>
            <div className='box-card mr-2 p-4' style={{ width: '100%' }}>
              <div className='grid grid-cols-4 gap-3'>
                <div className='mb-2'>
                  <label htmlFor="purchase-order-no" className='text-sm font-ubuntu'>
                    Order No
                  </label>
                  <input
                    id="purchase_order_no"
                    name="purchase_order_no"
                    type="text"
                    disabled={disableElement()}
                    {...register("purchase_order_no", { required: true })}
                    autoComplete="purchase_order_no"
                    className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.purchase_order_no ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.purchase_order_no ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.purchase_order_no ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.purchase_order_no && "error-input"}`}
                    placeholder=""
                  />
                  {errors.purchase_order_name && <span className='text-xs text-red-700'>This field is required</span>}
                </div>
                <div className='mb-2'>
                  <label htmlFor="purchase-order-date" className='text-sm'>
                    Order Date
                  </label>
                  <input
                    id="purchase_order_date"
                    name="purchase_order_date"
                    type="text"
                    disabled={disableElement()}
                    {...register("purchase_order_date", { required: true })}
                    autoComplete="purchase_order_date"
                    className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.purchase_order_date ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.purchase_order_date ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.purchase_order_date ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.purchase_order_date && "error-input"}`}
                    placeholder=""
                  />
                  {errors.purchase_order_date && <span className='text-xs text-red-700'>This field is required</span>}
                </div>
                <div className='mb-2'>
                  <label htmlFor="order-payment-due-date" className='text-sm font-ubuntu'>
                    Payment Due Date
                  </label>
                  <input
                    id="order_payment_due_date"
                    name="order_payment_due_date"
                    type="text"
                    disabled={disableElement()}
                    {...register("order_payment_due_date", { required: true })}
                    autoComplete="order_payment_due_date"
                    className={`relative block w-full appearance-none rounded-xs h-9 border-light-grey ${errors.order_payment_due_date ? "border-red-700" : `border-gray-500`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.order_payment_due_date ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.order_payment_due_date ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.order_payment_due_date && "error-input"}`}
                    placeholder=""
                  />
                  {errors.order_payment_due_date && <span className='text-xs text-red-700'>This field is required</span>}
                </div>
                <div className='mb-2'>
                  <label htmlFor="vendor" className='text-sm font-ubuntu'>
                    Select Vendor
                  </label>
                  <DropDownSelect
                    register={register}
                    options={categoryList}
                    defaultValue={categoryList[0]}
                    setSelected={(e) => { console.log(e, 'e') }}
                    control={control}
                    name={"vendor"}
                  />
                  {errors.vendor && <span className='text-xs text-red-700'>This field is required</span>}
                </div>
              </div>
              <div className='mt-3'>
                <div className='flex items-center justify-between'>
                    <div className='font-normal font-roboto'>Products</div>
                    <IconButton style={{ backgroundColor: 'rgba(0,0,0,0.1)' }} onClick={() => {
                        toggleProductsModal()
                      }}>
                        <PlusIcon style={{ height: 20, width: 20 }} />
                      </IconButton>
                </div>
                <TableComponent
                    column={columns}
                    docs={docs}
                    pagination={false}
                />
              </div>

              {actionType == "edit" && <div className="bg-gray-50 py-3 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={() => actionHandler()}
                  className="mt-3 inline-flex w-full justify-center rounded-sm bg-white px-3 py-1 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-sm bg-theme_color-600 px-3 py-1 text-sm text-white shadow-sm hover:bg-theme_color-700 sm:ml-3 sm:w-auto mr-3"
                  onClick={handleSubmit(onSubmit)}
                >
                  Confirm
                </button>
              </div>}
            </div>
          </div>
        </div>
      </form>
      <Transition 
          appear
          show={productsModal} 
          as={Fragment}>
          <Dialog as="div" 
            className="relative z-10"
            onClose={() => setProductsModal(false)}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-base font-normal leading-6 text-gray-900 font-roboto">
                    INVENTORY LIST
                  </Dialog.Title>
                  <TableComponent
                    column={columns1}
                    docs={docs1}
                    pagination={true}
                />
                <div className="bg-gray-50 py-3 sm:flex sm:flex-row-reverse">
                    <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-sm bg-white px-3 py-1 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => toggleProductsModal()}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-sm bg-theme_color-600 px-3 py-1 text-sm text-white shadow-sm hover:bg-theme_color-700 sm:ml-3 sm:w-auto mr-3"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Confirm
                    </button>
                    </div>
                </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
    </div>
  )
}

export default PurchaseOrderAction