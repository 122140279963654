import React, { Fragment } from 'react'
import moment from 'moment'
import { IoMdNotificationsOutline } from 'react-icons/io';
import IconButton from '@mui/material/IconButton';

import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function NotificationComponent() {
    return (
        <>
            <Menu as="div" className="relative inline-block text-left float-right">
                <div>
                    <Menu.Button className="inline-flex w-full justify-center rounded-md ">
                        <IconButton style={{ color: 'white' }}>
                            <span className='notification-count'>2</span>
                            <IoMdNotificationsOutline
                                color='gray'
                                size={25}
                            />
                        </IconButton>
                    </Menu.Button>
                </div>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-72 origin-top-right rounded-md bg-white shadow-lg ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        href="#"
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-2 text-sm'
                                        )}
                                    >
                                        <div className='flex flex-col'>
                                            <div className='flex items-center justify-between mb-2'>
                                                <div className='text-xs font-semibold'>New Service Request</div>
                                                <div className='text-gray-400 text-xs '>{moment().format('DD-MM-YYYY')}</div>
                                            </div>
                                            <p className='text-gray-400 text-xs'>new service request got from customer kathiravan</p>
                                        </div>
                                    </a>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        href="#"
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900 not_saw_notification' : 'text-gray-700',
                                            'block px-4 py-2 text-sm not_saw_notification'
                                        )}
                                    >
                                        <div className='flex flex-col'>
                                            <div className='flex items-center justify-between mb-2'>
                                                <div className='text-xs font-semibold'>Service Request Status Changed</div>
                                                <div className='text-gray-400 text-xs '>{moment().format('DD-MM-YYYY')}</div>
                                            </div>
                                            <p className='text-gray-400 text-xs'>service request no #84747 status has changed to billing.</p>
                                        </div>
                                    </a>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </>
    )
}

export default NotificationComponent