import React, { useState } from 'react'
import { ReactComponent as FilterSVG } from '../../resouces/svg/filter.svg'
import {useNavigate} from 'react-router-dom'

import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ComfirmModalComponent from '../../component/ui_component/confirm.modal';
import TableComponent from '../../component/ui_component/table';

function InventoryListPage(props) {
  const {setActionType} = props

    const navigation = useNavigate()
    const [deleteInventory, setDeleteInventory] = useState({modal: false, selected_details: {}})
  
    const columns = [
      {
        name: 'S.NO',
        selector: (row, index) => index + 1,
      },
      {
        name: 'INVENTORY NO',
        selector: row => row.inventory_no,
      },
      {
        name: 'INVENTORY NAME',
        selector: row => row.inventory_name,
      },
      {
        name: 'INVENTORY CATEGORY',
        selector: row => row.inventory_category,
      },
      {
        name: 'INVENTORY TYPE',
        selector: row => row.inventory_type,
      },
      {
        name: 'ACTION',
        selector: row => (
          <>
            <IconButton onClick={() => {
              NavigationHandler(`/inventory/${'937392'}/details`)
            }}>
              <VisibilityIcon style={{ color: 'rgba(0,0,0,0.3)', size: '7px' }} />
            </IconButton>
            <IconButton onClick={() => {
              toggle_delete_inventory()  
            }}>
              <DeleteOutlineIcon style={{ color: 'rgba(255,0,0,0.5)', size: '5px' }} />
            </IconButton>
          </>
        )
      }
    ];
  
    const toggle_delete_inventory = () => {
      setDeleteInventory({modal: !deleteInventory.modal, ...setDeleteInventory.selected_details})
    }
  
    const delete_inventory = (payload) => {
      console.log(payload, 'payload')
    }
  
    const NavigationHandler = (url) => {
      navigation(url)
    }
  
    const docs = [
      {
        inventory_no: '001',
        inventory_name: "break",
        inventory_category: "normal",
        inventory_type: "nos"
      },
      {
        inventory_no: '001',
        inventory_name: "break",
        inventory_category: "normal",
        inventory_type: "nos"
      },
      {
        inventory_no: '001',
        inventory_name: "break",
        inventory_category: "normal",
        inventory_type: "nos"
      },
      {
        inventory_no: '001',
        inventory_name: "break",
        inventory_category: "normal",
        inventory_type: "nos"
      },
      {
        inventory_no: '001',
        inventory_name: "break",
        inventory_category: "normal",
        inventory_type: "nos"
      },
      {
        inventory_no: '001',
        inventory_name: "break",
        inventory_category: "normal",
        inventory_type: "nos"
      },
      {
        inventory_no: '001',
        inventory_name: "break",
        inventory_category: "normal",
        inventory_type: "nos"
      },
      {
        inventory_no: '001',
        inventory_name: "break",
        inventory_category: "normal",
        inventory_type: "nos"
      },
      {
        inventory_no: '001',
        inventory_name: "break",
        inventory_category: "normal",
        inventory_type: "nos"
      },
      {
        inventory_no: '001',
        inventory_name: "break",
        inventory_category: "normal",
        inventory_type: "nos"
      }
    ]
    return (
      <div className='flex flex-col h-full p-5 pt-0'>
        <div className='flex items-center justify-between h-10 mb-2'>
          <div className='font-roboto font-bold'>Inventory List</div>
          <div>
            <button class="font-roboto bg-theme_color-600 hover:bg-theme_color-700 text-white text-sm py-1 px-4 border border-theme_color-700 rounded mr-2" type="button" onClick={() => NavigationHandler(`/inventory/add`)}>
              Add New
            </button>
            <IconButton type="button" deleteInventory >
              <FilterSVG style={{ color: "rgba(0,0,0,0.3)" }} />
            </IconButton>
          </div>
        </div>
        <div className='flex-grow pb-4'>
          <TableComponent
            column={columns}
            docs={docs}
          />
          <ComfirmModalComponent setProperty={toggle_delete_inventory} modal={deleteInventory.modal}/>
        </div>
      </div>
    )
}

export default InventoryListPage