import {createSlice} from '@reduxjs/toolkit'

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {value: {
        is_loading: false,
        data: {}
    }},
    reducers: {
        get_dashboard_details: (state, action) => {
            state.value = action.payload
        }
    }
})

export default dashboardSlice.reducer