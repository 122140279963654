import React, { useState } from 'react'
import { LockClosedIcon } from '@heroicons/react/20/solid'
import { useForm } from "react-hook-form";
import {
  useLocation,
  useNavigate,
  redirect
} from 'react-router-dom';
import theme from '../../constants/app.theme'
import { ThemeColor } from '../../utilities/theme.color';


const LoginPage = () =>  {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const onSubmit = data => console.log(data);

  const [hidePassword, setHidePassword] = useState(true)

  const navigate = useNavigate()

  console.log(errors, 'errors')

  console.log(watch("password")); // watch input value by passing the name of it

  const toggle_hide_password = () => {
    setHidePassword(!hidePassword)
  }
  
  const redirectToRegisterPage = () => {
    navigate('/register')
  }

  function Login(){
    localStorage.setItem('user', JSON.stringify({name:'kathiravan', email: "kathiravandeveloper@gmail.com"}))
    navigate('/')
  }

  return (
    <>
      <div className="flex min-h-full items-center justify-center sm:px-6 lg:px-8 dark:bg-black py-10">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src="https://tailwindui.com/img/logos/mark.svg?color=teal&shade=600"
              alt="Your Company"
            />
            <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              WORKSHOP MANAGEMENT SYSTEM
            </h2>
            {/*<p className="mt-2 text-center text-sm text-gray-600">
              Or{' '}
              <a href="#" className="font-medium text-${theme.bg_color}-600 hover:text-${theme.bg_color}-500">
                start your 14-day free trial
              </a>
            </p>*/}
          </div>

          <div className='bg-white rounded-md shadow-md p-10'>
            <form className="" onSubmit={handleSubmit(onSubmit)}>
              <div className="">
                <div className='mb-2'>
                  <label htmlFor="company-name" className='text-sm'>
                    Company Name
                  </label>
                  <input
                    id="company-name"
                    name="company_name"
                    type="text"
                    {...register("company_name", { required: true })}
                    autoComplete="company_name"
                    className={`relative block w-full appearance-none rounded-md border ${errors.company_name ? "border-red-700" : `border-gray-700`} px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 ${errors.company_name ? "focus:border-red-500" : `focus:border-theme_color-500`} focus:outline-none ${errors.company_name ? "focus:ring-red-500" : `focus:ring-theme_color-500`} sm:text-sm mb-1 ${errors.company_name && "error-input"}`}
                    placeholder=""
                  />
                  {errors.company_name && <span className='text-xs text-red-700'>This field is required</span>}
                </div>
                <div className='mb-2'>
                  <label htmlFor="email-address" className="text-sm">
                    Email Address
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    {...register("email", { required: true })}
                    autoComplete="email"
                    className={`relative block w-full appearance-none rounded-md border border-${errors.email ? "red" : "gray"}-700 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-${errors.email ? "red" : "theme_color"}-500 focus:outline-none focus:ring-${errors.email ? "red" : "theme_color"}-500 sm:text-sm mb-1 ${errors.email && "error-input"}`}
                    placeholder=""
                  />
                  {errors.email && <span className='text-xs text-red-700'>This field is required</span>}
                </div>
                <div className='mb-2'>
                  <label htmlFor="password" className="text-sm">
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    {...register("password", { required: true })}
                    type={hidePassword ? "password" : "text"}
                    autoComplete="current-password"
                    className={`relative block w-full appearance-none rounded-md border border-${errors.password ? "red" : "gray"}-700 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-${errors.password ? "red" : "theme_color"}-500 focus:outline-none focus:ring-${errors.password ? "red" : "theme_color"}-500 sm:text-sm mb-1 ${errors.password && "error-input"}`}
                    placeholder=""
                  />
                  {errors.password && <span className='text-xs text-red-700'>This field is required</span>}
                </div>
              </div>

              <div className="flex items-center justify-between mt-6 mb-6">
                <div className="flex items-center">
                  <input
                    id="show-password"
                    name="show-password"
                    type="checkbox"
                    defaultChecked={!hidePassword}
                    onChange={() => toggle_hide_password()}
                    className={`h-4 w-4 rounded border-gray-300 text-theme_color-600 ring-theme_color-500 focus:ring-theme_color-500 focus:ring-offset-2`}
                  />
                  <label htmlFor="show-password" className="ml-2 block text-sm text-gray-900">
                    Show Password
                  </label>
                </div>

                <div className="text-sm">
                  <a href="#" className={`font-medium text-theme_color-600 hover:text-theme_color-500"`}>
                    Forgot your password?
                  </a>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className={`group relative flex w-full justify-center rounded-md border border-transparent bg-theme_color-600 py-2 px-4 text-sm font-medium text-white hover:bg-theme_color-700 ring-theme_color-500 focus:outline-none focus:ring-2 focus:ring-theme_color-500 focus:ring-offset-2`}
                  onClick={() => Login()}
                >
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <LockClosedIcon className={`h-5 w-5 text-theme_color-500 group-hover:text-theme_color-400 aria-hidden="true`} />
                  </span>
                  Sign in
                </button>
              </div>

              <div className='flex align-middle justify-center mt-3'>
                <div className="text-sm">
                  Don't have account? &nbsp;
                  <a onClick={() => redirectToRegisterPage()} className={`font-medium text-theme_color-600 hover:text-theme_color-500 cursor-pointer`}>
                    Create Account
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )


}

export default LoginPage