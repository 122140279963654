import { IconButton } from '@mui/material'
import React, { useState } from 'react'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';


function ImageUploadComponent({setImageList, attachments, disabled, component, actionType}) {
    const [selectedImage, setSelectedImage] = useState(attachments || [])
    const FileSelect = (file) => {
        const clone = [...selectedImage]
        console.log(file, 'file')
        Object.values(file).forEach(x => {
            clone.push(x)
        })
        setSelectedImage(clone)
        setImageList(clone)
    }

    const deleteImage = (index) => {
        const clone = [...selectedImage]
        clone.splice(index, 1)
        setSelectedImage(clone)
        setImageList(clone)
    }

    function showAddButton(){
        if(component != "customer_vehicle" && component != "add_vehicle" && component != "inventory_add"){
            if(actionType == "edit") return true
            else return false
        }
        return true
    }

    return (
        <div class="my-4">
            {showAddButton() && (
                <React.Fragment>
                    <label class="inline-block mb-2 text-gray-500 text-sm">Upload
                        Image( jpg, png, svg, jpeg )</label>
                    <div class="flex items-center justify-center w-full">
                        <label class="flex flex-col w-full h-32 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                            <div class="flex flex-col items-center justify-center pt-7">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    class="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20"
                                    fill="currentColor">
                                    <path fill-rule="evenodd"
                                        d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                        clip-rule="evenodd" />
                                </svg>
                                <p class="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                    Select a photo</p>
                            </div>
                            <input disabled={disabled} type="file" multiple onChange={(e) => FileSelect(e.target.files)} class="opacity-0" />
                        </label>
                    </div>
                </React.Fragment>
            )}
            <div className='grid grid-cols-3 my-3 gap-4'>
                {selectedImage.map((file, index) => {
                    return (
                        <div className='relative transition-all'>
                            <img className='w-full' src={URL.createObjectURL(file)}/>
                            <span style={{position:'absolute', right: 5, bottom: 5}}>
                                <IconButton onClick={() => deleteImage(index)}>
                                    <DeleteOutlineIcon style={{ color: 'rgba(255,0,0,0.5)', size: '5px' }} />
                                </IconButton>
                            </span>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ImageUploadComponent