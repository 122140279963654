const color = "#4F46E5"
const c = "#0277bd"

const color_list = ["indigo", "teal"]
const theme = {
    bg_color: "indigo",
    theme_color: "teal",
    theme_color_code: "#0277bd",
    sidebar_bg_color: "#f6f8fc",
    sidebar_min_with: "70px",
    sidebar_max_with: "200px"
}

export default theme