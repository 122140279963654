import React, { useState } from 'react'
import { ReactComponent as FilterSVG } from '../../resouces/svg/filter.svg'
import DataTable from 'react-data-table-component';
import {useNavigate} from 'react-router-dom'

import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ComfirmModalComponent from '../../component/ui_component/confirm.modal';
import TableComponent from '../../component/ui_component/table';

function CustomerPage(props) {
  const {setActionType} = props

  const navigation = useNavigate()
  const [deleteCustomer, setDeleteCustomer] = useState({modal: false, selected_details: {}})

  const columns = [
    {
      name: 'S.NO',
      selector: (row, index) => index + 1,
    },
    {
      name: 'CUSTOMER UNIQUE ID',
      selector: row => row.customer_unique_id,
    },
    {
      name: 'CUSTOMER NAME',
      selector: row => row.customer_name,
    },
    {
      name: 'CUSTOMER PHONE',
      selector: row => row.customer_phone,
    },
    {
      name: 'CUSTOMER EMAIL',
      selector: row => row.customer_email,
    },
    {
      name: 'ACTION',
      selector: row => (
        <>
          <IconButton onClick={() => {
            // setSelectedUser(row)
            // setActionType('view')
            // setActionType('details')
            NavigationHandler(`/customer/${'937392'}/details`)
          }}>
            <VisibilityIcon style={{ color: 'rgba(0,0,0,0.3)', size: '7px' }} />
          </IconButton>
          <IconButton onClick={() => {
            toggle_delete_customer()  
            // setDeleteCustomer({modal: true, ...setDeleteCustomer.selected_details})
            // setSlideMessage(`Are you sure want delete ${row.customer_name}`)
            // setSelectedCustomer(row)
            // toggleOpenSlide()
          }}>
            <DeleteOutlineIcon style={{ color: 'rgba(255,0,0,0.5)', size: '5px' }} />
          </IconButton>
        </>
      )
    }
  ];

  const toggle_delete_customer = () => {
    setDeleteCustomer({modal: !deleteCustomer.modal, ...setDeleteCustomer.selected_details})
  }

  const delete_customer = (payload) => {
    console.log(payload, 'payload')
  }

  const NavigationHandler = (url) => {
    navigation(url)
  }

  const docs = [
    {
      customer_unique_id: '001',
      customer_name: "kathiravan",
      customer_phone: "76658855894",
      customer_email: "temp@gmail.com"
    },
    {
      customer_unique_id: '001',
      customer_name: "kathiravan",
      customer_phone: "76658855894",
      customer_email: "temp@gmail.com"
    },
    {
      customer_unique_id: '001',
      customer_name: "kathiravan",
      customer_phone: "76658855894",
      customer_email: "temp@gmail.com"
    },
    {
      customer_unique_id: '001',
      customer_name: "kathiravan",
      customer_phone: "76658855894",
      customer_email: "temp@gmail.com"
    },
    {
      customer_unique_id: '001',
      customer_name: "kathiravan",
      customer_phone: "76658855894",
      customer_email: "temp@gmail.com"
    },
    {
      customer_unique_id: '001',
      customer_name: "kathiravan",
      customer_phone: "76658855894",
      customer_email: "temp@gmail.com"
    },
    {
      customer_unique_id: '001',
      customer_name: "kathiravan",
      customer_phone: "76658855894",
      customer_email: "temp@gmail.com"
    },
    {
      customer_unique_id: '001',
      customer_name: "kathiravan",
      customer_phone: "76658855894",
      customer_email: "temp@gmail.com"
    },
    {
      customer_unique_id: '001',
      customer_name: "kathiravan",
      customer_phone: "76658855894",
      customer_email: "temp@gmail.com"
    },
    {
      customer_unique_id: '001',
      customer_name: "kathiravan",
      customer_phone: "76658855894",
      customer_email: "temp@gmail.com"
    }
  ]
  return (
    <div className='flex flex-col h-full p-5 pt-0'>
      <div className='flex items-center justify-between h-10 mb-2'>
        <div className='font-roboto font-bold'>Customer List</div>
        <div>
          <button class="font-roboto bg-theme_color-600 hover:bg-theme_color-700 text-white text-sm py-1 px-4 border border-theme_color-700 rounded mr-2" type="button" onClick={() => NavigationHandler(`/customer/add`)}>
            Add New
          </button>
          <IconButton type="button" deleteCustomer >
            <FilterSVG style={{ color: "rgba(0,0,0,0.3)" }} />
          </IconButton>
        </div>
      </div>
      <div className='flex-grow pb-4'>
        <TableComponent
          column={columns}
          docs={docs}
        />
        <ComfirmModalComponent setProperty={toggle_delete_customer} modal={deleteCustomer.modal}/>
      </div>
    </div>
  )
}

export default CustomerPage