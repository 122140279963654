import React, { useState } from 'react'
import VehicleModalComponent from '../../../component/ui_component/vehicle.modal'

function VehicleDetailsPageComponent() {
  const [actionType, setActionType] = useState('view')
  const [selectedVehicle, setSelectedVehicle] = useState({})

  function toggleActionType(){
    if(actionType == "view"){
      setActionType('edit')
    }else{
      setActionType('view')
    }
  }

  function ToggleVehicleModal(){

  }
  function vehicleModal(){

  }
  function AddVehicle(){

  }
  function EditVehicle(values){
    console.log(values, 'edit vehicle information')
  }
  return (
    <VehicleModalComponent
      selectedVehicle={selectedVehicle}
      EditVehicle={EditVehicle}
      toggleActionType={toggleActionType}
      component={"vehicle_details"}
      actionType={actionType}
    />
  )
}

export default VehicleDetailsPageComponent