import React,{Fragment} from 'react'
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { FiSearch } from 'react-icons/fi';



import theme from '../constants/app.theme'
import ProfileComponent from '../component/profile';
import NotificationComponent from '../component/notification';


function AppBarLayout(props) {
  const {toggle_sidebar} = props
  return (
    <div className={`flex h-full p-2 bg-white-600 shadow-md relative`}>
      <div className='flex w-full align-middle items-center'>
        <div className='flex items-center px-3 flex-grow'>
          <FiSearch color='gray' size={25} className="mr-3"/>
          <input placeholder='Search' className='bg-transparent flex flex-grow outline-none border-none p-2'/>
        </div>
        <div className='w-40 flex items-center justify-end'>
          <NotificationComponent/>
          <ProfileComponent/>
        </div>
      </div>
    </div>
  )
}

export default AppBarLayout