import React,{lazy} from "react";

const LoginPage = lazy( () => import('./auth/login.page'));
const RegisterPage = lazy(() => import('./auth/register.page'));
const ForgotPasswordPage = lazy(() => import('./auth/forgot_password.page'));
const DashboardPage = lazy(() => import('./home/dashboard.page'));

export default {
    LoginPage,
    RegisterPage,
    ForgotPasswordPage,
    DashboardPage
}